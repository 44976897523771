import { Mixpanel } from "@/helpers/mixpanel";

type AppstoreProps = {
  link?: boolean;
};

export default function Appstore(props: AppstoreProps) {
  return (
    <a
      href={
        props.link
          ? "https://apps.apple.com/app/apple-store/id1520060596?pt=121710263&ct=homepage&mt=8"
          : "https://apps.apple.com/us/app/dimensional-personality-test/id1520060596?utm_source=%22homepage%22"
      }
    >
      <svg
        width="174"
        height="58"
        viewBox="0 0 174 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M160.144 5.85619e-05H13.8641C13.3309 5.85619e-05 12.8041 5.8562e-05 12.2723 0.00295856C11.8271 0.00585856 11.3855 0.0142831 10.9361 0.0213736C9.95968 0.0328312 8.98558 0.118489 8.02225 0.277603C7.06027 0.440181 6.12843 0.746686 5.25824 1.18675C4.38911 1.63054 3.59496 2.2072 2.90461 2.89581C2.21063 3.58248 1.63214 4.37618 1.19139 5.24639C0.749428 6.11487 0.442969 7.04552 0.282599 8.0062C0.120704 8.96568 0.0335867 9.93621 0.0220292 10.9091C0.00853539 11.3537 0.00711041 11.7997 0 12.2444V45.76C0.00711041 46.2103 0.00853539 46.6464 0.0220292 47.0968C0.0335902 48.0696 0.120707 49.0401 0.282599 49.9995C0.442526 50.9608 0.749004 51.892 1.19139 52.7608C1.63194 53.6282 2.21051 54.4187 2.90461 55.1015C3.59235 55.7932 4.38701 56.3702 5.25824 56.8106C6.12842 57.2519 7.06017 57.5602 8.02225 57.7253C8.98575 57.8831 9.95974 57.9688 10.9361 57.9816C11.3855 57.9915 11.8271 57.9972 12.2723 57.9972C12.8041 58.0001 13.3309 58.0001 13.8641 58.0001H160.144C160.666 58.0001 161.197 58.0001 161.72 57.9972C162.163 57.9972 162.617 57.9915 163.06 57.9816C164.035 57.9695 165.007 57.8838 165.969 57.7253C166.934 57.559 167.869 57.2508 168.743 56.8106C169.614 56.37 170.408 55.7929 171.095 55.1015C171.787 54.416 172.367 53.6261 172.813 52.7608C173.252 51.8913 173.556 50.9602 173.713 49.9995C173.875 49.04 173.965 48.0697 173.983 47.0968C173.989 46.6464 173.989 46.2103 173.989 45.76C174 45.2332 174 44.7094 174 44.174V13.8274C174 13.2964 174 12.7697 173.989 12.2444C173.989 11.7997 173.989 11.3537 173.983 10.909C173.965 9.93607 173.875 8.96575 173.713 8.00615C173.555 7.04602 173.252 6.11544 172.813 5.24633C171.915 3.50209 170.492 2.0823 168.743 1.18662C167.869 0.747631 166.934 0.441207 165.969 0.277473C165.007 0.117658 164.035 0.0319698 163.06 0.0211706C162.617 0.0140946 162.163 0.00559756 161.72 0.00277006C161.197 -0.000129938 160.666 -0.000129938 160.144 -0.000129938V5.85619e-05Z"
            fill="black"
          />
          <path
            d="M12.2794 56.7313C11.8364 56.7313 11.404 56.7256 10.9645 56.7157C10.0539 56.7039 9.14548 56.6249 8.24662 56.4792C7.40848 56.3353 6.59655 56.0675 5.8376 55.6848C5.08562 55.3052 4.39975 54.8075 3.80627 54.2107C3.2042 53.621 2.70294 52.937 2.32237 52.1858C1.93761 51.4297 1.67133 50.6194 1.53281 49.7829C1.38323 48.884 1.30229 47.9752 1.29071 47.0641C1.28149 46.7583 1.26942 45.7401 1.26942 45.7401V12.2443C1.26942 12.2443 1.28228 11.2417 1.29078 10.9472C1.30187 10.0376 1.38234 9.13015 1.53148 8.23271C1.67025 7.39391 1.93674 6.58119 2.32171 5.82266C2.70088 5.07202 3.19935 4.3875 3.79779 3.79564C4.39556 3.19815 5.08363 2.69788 5.83689 2.31305C6.59409 1.93154 7.40449 1.66566 8.24094 1.52434C9.14275 1.37726 10.0543 1.29774 10.9681 1.28645L12.2801 1.26875H161.713L163.041 1.28717C163.946 1.29789 164.849 1.3767 165.743 1.52292C166.588 1.66601 167.407 1.93374 168.172 2.3173C169.681 3.09283 170.909 4.31979 171.684 5.8262C172.063 6.57949 172.325 7.38558 172.462 8.21714C172.613 9.12194 172.698 10.0365 172.715 10.9536C172.719 11.3642 172.719 11.8053 172.719 12.2443C172.731 12.788 172.731 13.3056 172.731 13.8274V44.174C172.731 44.7007 172.731 45.2148 172.719 45.733C172.719 46.2045 172.719 46.6365 172.713 47.0811C172.696 47.9818 172.613 48.88 172.465 49.7686C172.329 50.6112 172.064 51.4279 171.68 52.1901C171.296 52.9332 170.798 53.6112 170.203 54.1994C169.609 54.7994 168.922 55.3 168.168 55.682C167.404 56.0677 166.587 56.3365 165.743 56.4792C164.844 56.6256 163.935 56.7047 163.025 56.7157C162.599 56.7256 162.153 56.7313 161.72 56.7313L160.144 56.7342L12.2794 56.7313Z"
            fill="white"
          />
          <path
            d="M36.347 28.8396C36.3632 27.581 36.6985 26.347 37.3215 25.2524C37.9445 24.1578 38.8351 23.2381 39.9103 22.5789C39.2272 21.6061 38.3261 20.8055 37.2785 20.2407C36.2309 19.676 35.0657 19.3626 33.8753 19.3254C31.3362 19.0596 28.8746 20.8406 27.5807 20.8406C26.2617 20.8406 24.2696 19.3518 22.1243 19.3958C20.7367 19.4405 19.3844 19.8429 18.1991 20.5637C17.0138 21.2846 16.036 22.2993 15.3609 23.509C12.4366 28.5579 14.6179 35.9782 17.4191 40.0593C18.8207 42.0578 20.4587 44.2901 22.602 44.2109C24.6994 44.1242 25.4827 42.8772 28.0143 42.8772C30.5225 42.8772 31.2574 44.2109 33.4442 44.1606C35.6948 44.1242 37.1127 42.1533 38.4651 40.1359C39.4722 38.712 40.247 37.1382 40.7611 35.4729C39.4536 34.9214 38.3378 33.9983 37.5528 32.8187C36.7679 31.6392 36.3485 30.2552 36.347 28.8396Z"
            fill="black"
          />
          <path
            d="M32.2165 16.6414C33.4437 15.1724 34.0482 13.2843 33.9018 11.378C32.0271 11.5744 30.2954 12.4679 29.0517 13.8805C28.4436 14.5706 27.9779 15.3734 27.6812 16.2431C27.3844 17.1128 27.2625 18.0322 27.3223 18.9489C28.26 18.9586 29.1877 18.7559 30.0354 18.3562C30.8832 17.9565 31.629 17.3702 32.2165 16.6414V16.6414Z"
            fill="black"
          />
          <path
            d="M61.5098 39.3525H54.6271L52.9742 44.2193H50.059L56.5782 26.2132H59.607L66.1262 44.2193H63.1612L61.5098 39.3525ZM55.3399 37.1067H60.7956L58.1061 29.2081H58.0308L55.3399 37.1067Z"
            fill="black"
          />
          <path
            d="M80.2054 37.6561C80.2054 41.7357 78.0157 44.3567 74.7114 44.3567C73.8744 44.4004 73.0419 44.2081 72.3094 43.8019C71.5768 43.3957 70.9738 42.792 70.5693 42.0599H70.5068V48.5622H67.8046V31.0915H70.4202V33.275H70.4699C70.8929 32.5464 71.5062 31.9458 72.2443 31.5371C72.9824 31.1284 73.8178 30.927 74.6617 30.9541C78.003 30.9541 80.2054 33.5879 80.2054 37.6561ZM77.4279 37.6561C77.4279 34.9983 76.0505 33.2509 73.9489 33.2509C71.8842 33.2509 70.4955 35.0351 70.4955 37.6561C70.4955 40.3012 71.8842 42.0727 73.9489 42.0727C76.0505 42.0727 77.4278 40.338 77.4278 37.6561H77.4279Z"
            fill="black"
          />
          <path
            d="M94.6957 37.6561C94.6957 41.7357 92.5053 44.3567 89.201 44.3567C88.364 44.4004 87.5315 44.2081 86.799 43.8019C86.0664 43.3957 85.4634 42.792 85.0589 42.0599H84.9964V48.5622H82.2941V31.0915H84.9098V33.275H84.9595C85.3825 32.5464 85.9957 31.9458 86.7339 31.5371C87.472 31.1284 88.3074 30.927 89.1513 30.9541C92.4926 30.9541 94.6957 33.5879 94.6957 37.6561ZM91.9175 37.6561C91.9175 34.9983 90.5401 33.2509 88.4385 33.2509C86.3738 33.2509 84.9851 35.0351 84.9851 37.6561C84.9851 40.3012 86.3738 42.0727 88.4385 42.0727C90.5401 42.0727 91.9175 40.338 91.9175 37.6561V37.6561Z"
            fill="black"
          />
          <path
            d="M104.271 39.2024C104.471 40.988 106.21 42.1604 108.587 42.1604C110.865 42.1604 112.504 40.9879 112.504 39.3779C112.504 37.9803 111.515 37.1435 109.175 36.57L106.835 36.0078C103.52 35.2092 101.98 33.6629 101.98 31.1537C101.98 28.0469 104.695 25.913 108.549 25.913C112.366 25.913 114.982 28.0469 115.07 31.1537H112.342C112.179 29.3568 110.689 28.2721 108.512 28.2721C106.335 28.2721 104.846 29.3695 104.846 30.9668C104.846 32.2398 105.797 32.9888 108.125 33.5623L110.114 34.0494C113.819 34.9231 115.357 36.4071 115.357 39.0408C115.357 42.4095 112.667 44.5194 108.387 44.5194C104.383 44.5194 101.679 42.4591 101.504 39.2023L104.271 39.2024Z"
            fill="black"
          />
          <path
            d="M121.191 27.9847V31.0915H123.695V33.2254H121.191V40.4626C121.191 41.587 121.693 42.1109 122.793 42.1109C123.09 42.1057 123.387 42.0849 123.682 42.0485V44.1697C123.187 44.2619 122.684 44.3037 122.181 44.2943C119.516 44.2943 118.476 43.296 118.476 40.75V33.2254H116.562V31.0915H118.476V27.9847H121.191Z"
            fill="black"
          />
          <path
            d="M125.143 37.6561C125.143 33.5256 127.583 30.93 131.387 30.93C135.204 30.93 137.632 33.5255 137.632 37.6561C137.632 41.798 135.217 44.3822 131.387 44.3822C127.559 44.3822 125.143 41.798 125.143 37.6561ZM134.879 37.6561C134.879 34.8227 133.576 33.1504 131.387 33.1504C129.197 33.1504 127.895 34.8354 127.895 37.6561C127.895 40.5009 129.197 42.1605 131.387 42.1605C133.576 42.1605 134.879 40.5009 134.879 37.6561H134.879Z"
            fill="black"
          />
          <path
            d="M139.86 31.0915H142.437V33.3259H142.5C142.674 32.628 143.084 32.0113 143.661 31.5794C144.237 31.1474 144.946 30.9266 145.666 30.9541C145.978 30.953 146.288 30.9867 146.592 31.0546V33.5751C146.199 33.4553 145.789 33.4003 145.378 33.4123C144.985 33.3964 144.594 33.4654 144.231 33.6146C143.867 33.7638 143.541 33.9896 143.273 34.2766C143.006 34.5635 142.804 34.9048 142.681 35.2769C142.558 35.6491 142.518 36.0434 142.562 36.4326V44.2193H139.86L139.86 31.0915Z"
            fill="black"
          />
          <path
            d="M159.051 40.3635C158.688 42.7467 156.36 44.3822 153.382 44.3822C149.553 44.3822 147.176 41.8234 147.176 37.7184C147.176 33.6006 149.566 30.93 153.269 30.93C156.911 30.93 159.202 33.425 159.202 37.4055V38.3287H149.903V38.4915C149.861 38.9747 149.921 39.4614 150.081 39.9194C150.242 40.3773 150.498 40.7961 150.833 41.1478C151.168 41.4995 151.574 41.7761 152.024 41.9592C152.475 42.1423 152.959 42.2278 153.445 42.21C154.084 42.2697 154.724 42.1222 155.272 41.7895C155.82 41.4568 156.245 40.9567 156.485 40.3635L159.051 40.3635ZM149.916 36.4454H156.498C156.522 36.011 156.456 35.5763 156.304 35.1685C156.152 34.7608 155.917 34.3888 155.613 34.0759C155.31 33.7631 154.945 33.5161 154.542 33.3505C154.139 33.1848 153.705 33.1042 153.269 33.1135C152.829 33.1109 152.393 33.1952 151.985 33.3616C151.578 33.528 151.208 33.7731 150.896 34.0829C150.585 34.3926 150.338 34.7609 150.169 35.1664C150.001 35.5719 149.915 36.0066 149.916 36.4454V36.4454Z"
            fill="black"
          />
          <path
            d="M55.0019 12.6599C55.5685 12.6194 56.137 12.7048 56.6664 12.9098C57.1959 13.1149 57.6731 13.4346 58.0636 13.8459C58.4541 14.2571 58.7483 14.7497 58.9248 15.288C59.1013 15.8264 59.1559 16.3971 59.0844 16.959C59.0844 19.723 57.5863 21.3119 55.0019 21.3119H51.868V12.6599H55.0019ZM53.2156 20.0883H54.8514C55.2562 20.1124 55.6614 20.0462 56.0373 19.8946C56.4132 19.7429 56.7505 19.5095 57.0246 19.2114C57.2987 18.9133 57.5027 18.5581 57.6217 18.1715C57.7407 17.7849 57.7718 17.3767 57.7127 16.9766C57.7675 16.5781 57.7333 16.1724 57.6124 15.7887C57.4916 15.4049 57.2871 15.0525 57.0136 14.7568C56.7402 14.4611 56.4045 14.2294 56.0307 14.0783C55.6569 13.9271 55.2542 13.8603 54.8514 13.8826H53.2156V20.0883Z"
            fill="black"
          />
          <path
            d="M60.6066 18.0443C60.5655 17.6152 60.6147 17.1823 60.7512 16.7733C60.8876 16.3643 61.1083 15.9882 61.399 15.6693C61.6897 15.3503 62.0441 15.0954 62.4394 14.921C62.8347 14.7466 63.2622 14.6565 63.6944 14.6565C64.1267 14.6565 64.5542 14.7466 64.9494 14.921C65.3447 15.0954 65.6991 15.3503 65.9898 15.6693C66.2806 15.9882 66.5012 16.3643 66.6377 16.7733C66.7741 17.1823 66.8234 17.6152 66.7822 18.0443C66.8241 18.4738 66.7755 18.9073 66.6394 19.317C66.5033 19.7267 66.2828 20.1034 65.992 20.423C65.7012 20.7426 65.3465 20.998 64.9509 21.1728C64.5552 21.3475 64.1272 21.4378 63.6944 21.4378C63.2617 21.4378 62.8337 21.3475 62.438 21.1728C62.0423 20.998 61.6877 20.7426 61.3969 20.423C61.1061 20.1034 60.8855 19.7267 60.7494 19.317C60.6133 18.9073 60.5647 18.4738 60.6066 18.0443V18.0443ZM65.4531 18.0443C65.4531 16.629 64.8155 15.8013 63.6965 15.8013C62.5733 15.8013 61.9415 16.629 61.9415 18.0443C61.9415 19.4709 62.5734 20.2922 63.6965 20.2922C64.8155 20.2922 65.4531 19.4653 65.4531 18.0443H65.4531Z"
            fill="black"
          />
          <path
            d="M74.9912 21.3117H73.6507L72.2974 16.5029H72.1952L70.8476 21.3117H69.5199L67.7151 14.7825H69.0258L70.1987 19.7647H70.2953L71.6414 14.7825H72.8811L74.2272 19.7647H74.3295L75.4967 14.7825H76.7889L74.9912 21.3117Z"
            fill="black"
          />
          <path
            d="M78.3068 14.7825H79.5507V15.8197H79.6473C79.8111 15.4472 80.0874 15.1349 80.4376 14.9264C80.7879 14.7179 81.1946 14.6235 81.6012 14.6565C81.9198 14.6326 82.2397 14.6805 82.5372 14.7966C82.8347 14.9127 83.1022 15.0942 83.3198 15.3274C83.5375 15.5606 83.6997 15.8397 83.7945 16.1439C83.8893 16.4481 83.9143 16.7698 83.8675 17.0849V21.3117H82.5753V17.4085C82.5753 16.3592 82.1181 15.8374 81.1624 15.8374C80.9461 15.8274 80.7302 15.8641 80.5295 15.945C80.3287 16.026 80.1479 16.1493 79.9994 16.3064C79.8509 16.4636 79.7382 16.6509 79.6691 16.8556C79.6 17.0602 79.5761 17.2773 79.599 17.492V21.3117H78.3068L78.3068 14.7825Z"
            fill="black"
          />
          <path
            d="M85.9265 12.2337H87.2187V21.3117H85.9265V12.2337Z"
            fill="black"
          />
          <path
            d="M89.015 18.0443C88.9739 17.6152 89.0231 17.1822 89.1596 16.7732C89.2961 16.3642 89.5168 15.9881 89.8075 15.6692C90.0983 15.3502 90.4527 15.0953 90.848 14.9209C91.2433 14.7465 91.6708 14.6564 92.1031 14.6564C92.5354 14.6564 92.9629 14.7465 93.3582 14.9209C93.7535 15.0953 94.1079 15.3502 94.3987 15.6692C94.6894 15.9881 94.9101 16.3642 95.0466 16.7732C95.1831 17.1822 95.2324 17.6152 95.1912 18.0443C95.2331 18.4738 95.1844 18.9074 95.0483 19.317C94.9122 19.7267 94.6916 20.1034 94.4008 20.423C94.1099 20.7426 93.7553 20.998 93.3596 21.1727C92.9639 21.3475 92.5359 21.4378 92.1031 21.4378C91.6703 21.4378 91.2423 21.3475 90.8466 21.1727C90.4509 20.998 90.0963 20.7426 89.8054 20.423C89.5146 20.1034 89.294 19.7267 89.1579 19.317C89.0218 18.9074 88.9731 18.4738 89.015 18.0443V18.0443ZM93.8614 18.0443C93.8614 16.629 93.2238 15.8013 92.1049 15.8013C90.9817 15.8013 90.3498 16.629 90.3498 18.0443C90.3498 19.4709 90.9817 20.2922 92.1049 20.2922C93.2238 20.2922 93.8614 19.4653 93.8614 18.0443H93.8614Z"
            fill="black"
          />
          <path
            d="M96.5516 19.4653C96.5516 18.29 97.4291 17.6124 98.9869 17.5161L100.76 17.4142V16.8506C100.76 16.161 100.303 15.7716 99.42 15.7716C98.6986 15.7716 98.1988 16.0357 98.0554 16.4973H96.8043C96.9364 15.3758 97.9943 14.6565 99.4796 14.6565C101.121 14.6565 102.047 15.4714 102.047 16.8506V21.3118H100.803V20.3942H100.701C100.493 20.7233 100.202 20.9916 99.8564 21.1717C99.5108 21.3518 99.1236 21.4373 98.7341 21.4193C98.4593 21.4478 98.1814 21.4186 97.9186 21.3335C97.6558 21.2484 97.4137 21.1093 97.2081 20.9252C97.0025 20.7411 96.8378 20.516 96.7247 20.2646C96.6117 20.0131 96.5527 19.7408 96.5516 19.4653V19.4653ZM100.76 18.9073V18.3615L99.1615 18.4634C98.2598 18.5236 97.8509 18.8295 97.8509 19.4051C97.8509 19.9927 98.3621 20.3347 99.065 20.3347C99.271 20.3555 99.479 20.3347 99.6768 20.2737C99.8746 20.2127 100.058 20.1126 100.216 19.9794C100.374 19.8463 100.504 19.6827 100.598 19.4985C100.691 19.3144 100.746 19.1133 100.76 18.9073V18.9073Z"
            fill="black"
          />
          <path
            d="M103.745 18.0443C103.745 15.9812 104.809 14.6742 106.463 14.6742C106.872 14.6554 107.278 14.7531 107.634 14.956C107.99 15.1588 108.28 15.4584 108.471 15.8197H108.568V12.2337H109.86V21.3117H108.622V20.2801H108.519C108.313 20.639 108.013 20.9349 107.651 21.1357C107.288 21.3365 106.877 21.4346 106.463 21.4193C104.797 21.4194 103.745 20.1124 103.745 18.0443ZM105.08 18.0443C105.08 19.4292 105.735 20.2625 106.83 20.2625C107.919 20.2625 108.592 19.4171 108.592 18.05C108.592 16.6892 107.912 15.8318 106.83 15.8318C105.742 15.8318 105.08 16.6708 105.08 18.0443H105.08Z"
            fill="black"
          />
          <path
            d="M115.206 18.0443C115.165 17.6152 115.214 17.1823 115.351 16.7733C115.487 16.3643 115.708 15.9882 115.998 15.6693C116.289 15.3503 116.643 15.0954 117.039 14.921C117.434 14.7466 117.862 14.6565 118.294 14.6565C118.726 14.6565 119.154 14.7466 119.549 14.921C119.944 15.0954 120.298 15.3503 120.589 15.6693C120.88 15.9882 121.101 16.3643 121.237 16.7733C121.373 17.1823 121.423 17.6152 121.382 18.0443C121.423 18.4738 121.375 18.9073 121.239 19.317C121.103 19.7267 120.882 20.1034 120.591 20.423C120.301 20.7426 119.946 20.998 119.55 21.1728C119.155 21.3475 118.727 21.4378 118.294 21.4378C117.861 21.4378 117.433 21.3475 117.037 21.1728C116.642 20.998 116.287 20.7426 115.996 20.423C115.705 20.1034 115.485 19.7267 115.349 19.317C115.213 18.9073 115.164 18.4738 115.206 18.0443V18.0443ZM120.052 18.0443C120.052 16.629 119.415 15.8013 118.296 15.8013C117.173 15.8013 116.541 16.629 116.541 18.0443C116.541 19.4709 117.173 20.2922 118.296 20.2922C119.415 20.2922 120.052 19.4653 120.052 18.0443Z"
            fill="black"
          />
          <path
            d="M123.115 14.7825H124.359V15.8197H124.456C124.62 15.4472 124.896 15.1349 125.246 14.9264C125.596 14.7179 126.003 14.6235 126.41 14.6565C126.728 14.6326 127.048 14.6805 127.346 14.7966C127.643 14.9127 127.911 15.0942 128.128 15.3274C128.346 15.5606 128.508 15.8397 128.603 16.1439C128.698 16.4481 128.723 16.7698 128.676 17.0849V21.3117H127.384V17.4085C127.384 16.3592 126.927 15.8374 125.971 15.8374C125.755 15.8274 125.539 15.8641 125.338 15.945C125.137 16.026 124.956 16.1493 124.808 16.3064C124.659 16.4636 124.547 16.6509 124.478 16.8556C124.409 17.0602 124.385 17.2773 124.408 17.492V21.3117H123.115V14.7825Z"
            fill="black"
          />
          <path
            d="M135.978 13.1569V14.8122H137.396V15.8976H135.978V19.255C135.978 19.9389 136.26 20.2384 136.903 20.2384C137.068 20.2379 137.233 20.2279 137.396 20.2087V21.282C137.164 21.3234 136.929 21.3454 136.693 21.3478C135.256 21.3478 134.684 20.8437 134.684 19.5849V15.8976H133.645V14.8122H134.684V13.1569H135.978Z"
            fill="black"
          />
          <path
            d="M139.161 12.2337H140.442V15.8318H140.544C140.716 15.4557 141 15.1417 141.357 14.9324C141.714 14.7231 142.128 14.6287 142.541 14.6621C142.858 14.6449 143.175 14.6977 143.469 14.8167C143.763 14.9356 144.027 15.1178 144.242 15.3503C144.458 15.5828 144.619 15.8598 144.715 16.1616C144.81 16.4634 144.838 16.7826 144.796 17.0963V21.3117H143.502V17.4141C143.502 16.3712 143.015 15.8431 142.102 15.8431C141.88 15.8249 141.657 15.8553 141.448 15.9323C141.239 16.0092 141.049 16.1308 140.892 16.2884C140.735 16.4461 140.614 16.6361 140.538 16.8451C140.463 17.0541 140.434 17.277 140.454 17.4984V21.3117H139.161L139.161 12.2337Z"
            fill="black"
          />
          <path
            d="M152.33 19.5488C152.155 20.1455 151.774 20.6617 151.256 21.007C150.737 21.3523 150.113 21.5048 149.493 21.4378C149.062 21.4491 148.633 21.3667 148.237 21.1963C147.841 21.0259 147.486 20.7715 147.199 20.4509C146.911 20.1302 146.696 19.751 146.57 19.3394C146.444 18.9279 146.41 18.4939 146.469 18.0677C146.411 17.6401 146.446 17.2052 146.572 16.7924C146.698 16.3796 146.911 15.9986 147.197 15.6751C147.484 15.3517 147.836 15.0933 148.231 14.9176C148.626 14.7418 149.055 14.6528 149.487 14.6565C151.309 14.6565 152.408 15.8977 152.408 17.948V18.3976H147.785V18.4698C147.765 18.7094 147.795 18.9506 147.874 19.1779C147.952 19.4052 148.078 19.6135 148.242 19.7897C148.406 19.9658 148.605 20.1057 148.827 20.2006C149.048 20.2954 149.287 20.343 149.529 20.3403C149.838 20.3773 150.151 20.3218 150.428 20.1809C150.706 20.0399 150.935 19.8199 151.086 19.5488L152.33 19.5488ZM147.785 17.4446H151.092C151.108 17.2255 151.078 17.0054 151.004 16.7984C150.93 16.5915 150.814 16.4023 150.662 16.243C150.51 16.0837 150.326 15.9578 150.123 15.8735C149.919 15.7891 149.701 15.7481 149.48 15.7532C149.257 15.7504 149.035 15.7922 148.828 15.8762C148.621 15.9603 148.433 16.0848 148.275 16.2424C148.117 16.4 147.992 16.5876 147.908 16.7941C147.824 17.0006 147.782 17.2217 147.785 17.4446H147.785Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="174" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

export function AppStoreNew(props: AppstoreProps) {
  return (
    <a
      onClick={() => {
        Mixpanel?.track("App/Play Store link clicked from homepage", {
          type: "AppStore",
        });
      }}
      href={
        props.link
          ? "https://apps.apple.com/app/apple-store/id1520060596?pt=121710263&ct=homepage&mt=8"
          : "https://apps.apple.com/us/app/dimensional-personality-test/id1520060596?utm_source=%22homepage%22"
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="228"
        height="76"
        viewBox="0 0 228 76"
        fill="none"
      >
        <g clipPath="url(#clip0_57531_51309)">
          <path
            d="M209.844 2.85936e-06H18.1667C17.4681 2.85936e-06 16.7778 2.85948e-06 16.0809 0.00380286C15.4976 0.00760286 14.9189 0.0186419 14.33 0.0279329C13.0506 0.0429463 11.7742 0.155188 10.5119 0.363682C9.25139 0.576715 8.03036 0.978343 6.89011 1.55498C5.75125 2.1365 4.71064 2.89212 3.80604 3.79444C2.89668 4.69421 2.13867 5.73423 1.56114 6.87451C0.98201 8.01251 0.580442 9.23199 0.370302 10.4908C0.158164 11.7481 0.0440101 13.0198 0.0288658 14.2946C0.0111843 14.8772 0.00931708 15.4616 0 16.0442V59.9613C0.00931708 60.5513 0.0111843 61.1228 0.0288658 61.713C0.0440148 62.9877 0.158168 64.2594 0.370302 65.5166C0.579862 66.7761 0.981453 67.9963 1.56114 69.1347C2.1384 70.2713 2.89653 71.3071 3.80604 72.2019C4.70721 73.1082 5.74849 73.8644 6.89011 74.4414C8.03035 75.0196 9.25125 75.4236 10.5119 75.64C11.7744 75.8468 13.0507 75.9591 14.33 75.9759C14.9189 75.9888 15.4976 75.9962 16.0809 75.9962C16.7777 76 17.4681 76 18.1667 76H209.844C210.528 76 211.224 76 211.909 75.9962C212.489 75.9962 213.085 75.9888 213.665 75.9759C214.942 75.96 216.216 75.8477 217.476 75.64C218.741 75.4221 219.966 75.0182 221.112 74.4414C222.252 73.864 223.293 73.1079 224.193 72.2019C225.1 71.3036 225.86 70.2686 226.444 69.1347C227.02 67.9955 227.418 66.7754 227.624 65.5166C227.837 64.2592 227.955 62.9878 227.978 61.713C227.985 61.1228 227.985 60.5513 227.985 59.9613C228 59.2711 228 58.5846 228 57.8831V18.1186C228 17.4228 228 16.7326 227.985 16.0442C227.985 15.4616 227.985 14.8772 227.978 14.2945C227.955 13.0196 227.837 11.7482 227.624 10.4907C227.417 9.23264 227.019 8.01326 226.444 6.87443C225.268 4.58888 223.403 2.72845 221.112 1.55481C219.966 0.979581 218.741 0.57806 217.476 0.363511C216.216 0.154098 214.942 0.0418176 213.665 0.0276669C213.085 0.0183949 212.489 0.00726086 211.909 0.00355586C211.224 -0.000244141 210.528 -0.000244141 209.844 -0.000244141V2.85936e-06Z"
            fill="#181716"
          />
          <path
            d="M16.0904 74.3374C15.5099 74.3374 14.9434 74.3299 14.3674 74.317C13.1743 74.3015 11.9839 74.1979 10.8061 74.0071C9.70786 73.8185 8.64394 73.4677 7.64947 72.9661C6.66411 72.4688 5.76538 71.8166 4.98771 71.0346C4.19879 70.2618 3.54198 69.3655 3.0433 68.3812C2.53913 67.3906 2.19021 66.3287 2.00871 65.2326C1.81269 64.0548 1.70664 62.8639 1.69147 61.6701C1.67939 61.2693 1.66357 59.9352 1.66357 59.9352V16.0441C1.66357 16.0441 1.68042 14.7304 1.69156 14.3445C1.70609 13.1526 1.81153 11.9635 2.00695 10.7875C2.1888 9.68843 2.53798 8.62348 3.04243 7.62955C3.53927 6.64595 4.19244 5.74899 4.97661 4.97345C5.75989 4.19053 6.6615 3.535 7.64853 3.03075C8.64072 2.53083 9.70263 2.18245 10.7987 1.99727C11.9803 1.80454 13.1748 1.70035 14.3721 1.68555L16.0914 1.66235H211.9L213.639 1.68648C214.826 1.70054 216.01 1.80381 217.18 1.9954C218.287 2.18291 219.36 2.53372 220.364 3.03632C222.341 4.05253 223.95 5.66026 224.965 7.63419C225.462 8.62125 225.806 9.67752 225.985 10.7671C226.183 11.9527 226.294 13.1512 226.316 14.3528C226.322 14.8909 226.322 15.4689 226.322 16.0441C226.337 16.7566 226.337 17.4348 226.337 18.1185V57.883C226.337 58.5732 226.337 59.2468 226.322 59.9259C226.322 60.5437 226.322 61.1097 226.314 61.6923C226.292 62.8725 226.183 64.0495 225.989 65.2139C225.811 66.318 225.464 67.3881 224.96 68.3869C224.458 69.3605 223.805 70.2491 223.025 71.0198C222.246 71.806 221.346 72.462 220.358 72.9625C219.358 73.4679 218.286 73.8201 217.18 74.0071C216.003 74.199 214.812 74.3026 213.619 74.317C213.061 74.3299 212.476 74.3374 211.909 74.3374L209.844 74.3412L16.0904 74.3374Z"
            fill="#FFFFF2"
          />
          <path
            d="M47.6267 37.7898C47.648 36.1407 48.0873 34.5237 48.9037 33.0894C49.72 31.6551 50.887 30.4499 52.2959 29.5862C51.4009 28.3115 50.2201 27.2624 48.8473 26.5224C47.4746 25.7824 45.9478 25.3717 44.388 25.323C41.0609 24.9748 37.8353 27.3084 36.1398 27.3084C34.4116 27.3084 31.8011 25.3576 28.9901 25.4153C27.1719 25.4739 25.3999 26.0011 23.8467 26.9457C22.2936 27.8902 21.0123 29.2198 20.1277 30.805C16.2959 37.4208 19.1541 47.1438 22.8247 52.4916C24.6612 55.1102 26.8076 58.0353 29.6161 57.9316C32.3643 57.8179 33.3907 56.184 36.7081 56.184C39.9947 56.184 40.9577 57.9316 43.8231 57.8656C46.7722 57.8179 48.6301 55.2354 50.4023 52.5919C51.7218 50.7261 52.7372 48.6639 53.4108 46.4818C51.6975 45.7591 50.2354 44.5496 49.2068 43.0039C48.1783 41.4583 47.6287 39.6449 47.6267 37.7898Z"
            fill="black"
          />
          <path
            d="M42.2148 21.8057C43.8227 19.8809 44.6149 17.4068 44.423 14.9089C41.9665 15.1662 39.6973 16.337 38.0677 18.188C37.2709 19.0923 36.6607 20.1442 36.2718 21.2838C35.883 22.4234 35.7232 23.6282 35.8016 24.8294C37.0303 24.8421 38.2459 24.5765 39.3567 24.0527C40.4676 23.529 41.4448 22.7607 42.2148 21.8057Z"
            fill="black"
          />
          <path
            d="M80.599 51.5651H71.5803L69.4144 57.9423H65.5945L74.1369 34.3481H78.1057L86.648 57.9423H82.7629L80.599 51.5651ZM72.5143 48.6223H79.6632L76.139 38.2725H76.0403L72.5143 48.6223Z"
            fill="black"
          />
          <path
            d="M105.097 49.3422C105.097 54.6879 102.227 58.1223 97.8976 58.1223C96.8007 58.1795 95.7099 57.9276 94.75 57.3953C93.7901 56.8631 93 56.072 92.47 55.1127H92.388V63.633H88.8472V40.7403H92.2745V43.6014H92.3397C92.894 42.6467 93.6976 41.8598 94.6648 41.3243C95.632 40.7888 96.7267 40.5247 97.8324 40.5603C102.211 40.5603 105.097 44.0115 105.097 49.3422ZM101.457 49.3422C101.457 45.8596 99.6522 43.5699 96.8984 43.5699C94.1929 43.5699 92.3732 45.9078 92.3732 49.3422C92.3732 52.8083 94.1929 55.1295 96.8984 55.1295C99.6522 55.1295 101.457 52.8565 101.457 49.3422H101.457Z"
            fill="black"
          />
          <path
            d="M124.084 49.3422C124.084 54.6879 121.214 58.1223 116.884 58.1223C115.787 58.1795 114.697 57.9276 113.737 57.3953C112.777 56.8631 111.987 56.072 111.457 55.1127H111.375V63.633H107.834V40.7403H111.261V43.6014H111.326C111.881 42.6467 112.684 41.8598 113.651 41.3243C114.619 40.7888 115.713 40.5247 116.819 40.5603C121.197 40.5603 124.084 44.0115 124.084 49.3422ZM120.444 49.3422C120.444 45.8596 118.639 43.5699 115.885 43.5699C113.18 43.5699 111.36 45.9078 111.36 49.3422C111.36 52.8083 113.18 55.1295 115.885 55.1295C118.639 55.1295 120.444 52.8565 120.444 49.3422Z"
            fill="black"
          />
          <path
            d="M136.63 51.3685C136.893 53.7083 139.172 55.2445 142.287 55.2445C145.271 55.2445 147.419 53.7082 147.419 51.5985C147.419 49.7671 146.123 48.6706 143.057 47.9191L139.991 47.1825C135.646 46.136 133.629 44.1098 133.629 40.8219C133.629 36.751 137.187 33.9548 142.237 33.9548C147.238 33.9548 150.665 36.751 150.781 40.8219H147.206C146.992 38.4673 145.041 37.0461 142.188 37.0461C139.336 37.0461 137.384 38.4841 137.384 40.577C137.384 42.2451 138.631 43.2266 141.68 43.978L144.287 44.6163C149.141 45.7611 151.157 47.7057 151.157 51.1568C151.157 55.571 147.632 58.3356 142.024 58.3356C136.777 58.3356 133.234 55.6359 133.006 51.3683L136.63 51.3685Z"
            fill="black"
          />
          <path
            d="M158.802 36.6694V40.7403H162.082V43.5365H158.802V53.0198C158.802 54.4931 159.459 55.1796 160.901 55.1796C161.29 55.1728 161.679 55.1455 162.066 55.0979V57.8774C161.417 57.9982 160.758 58.0529 160.099 58.0407C156.606 58.0407 155.244 56.7326 155.244 53.3964V43.5365H152.736V40.7403H155.244V36.6694H158.802Z"
            fill="black"
          />
          <path
            d="M163.98 49.3423C163.98 43.9299 167.177 40.5288 172.162 40.5288C177.163 40.5288 180.345 43.9298 180.345 49.3423C180.345 54.7695 177.18 58.1558 172.162 58.1558C167.145 58.1558 163.98 54.7695 163.98 49.3423ZM176.737 49.3423C176.737 45.6295 175.031 43.4382 172.162 43.4382C169.292 43.4382 167.586 45.6462 167.586 49.3423C167.586 53.0699 169.292 55.2445 172.162 55.2445C175.031 55.2445 176.737 53.0699 176.737 49.3423H176.737Z"
            fill="black"
          />
          <path
            d="M183.264 40.7404H186.642V43.6683H186.723C186.952 42.7538 187.489 41.9457 188.245 41.3797C189 40.8137 189.928 40.5244 190.873 40.5604C191.281 40.5589 191.688 40.6031 192.086 40.6921V43.9948C191.571 43.8379 191.033 43.7658 190.495 43.7815C189.981 43.7607 189.468 43.8511 188.992 44.0466C188.516 44.2421 188.088 44.538 187.737 44.914C187.387 45.29 187.122 45.7371 186.961 46.2248C186.8 46.7125 186.747 47.2291 186.805 47.7392V57.9424H183.264L183.264 40.7404Z"
            fill="black"
          />
          <path
            d="M208.412 52.8899C207.935 56.0127 204.886 58.1558 200.984 58.1558C195.966 58.1558 192.851 54.8029 192.851 49.4239C192.851 44.0282 195.982 40.5288 200.835 40.5288C205.608 40.5288 208.609 43.7981 208.609 49.0139V50.2236H196.425V50.437C196.369 51.0701 196.448 51.7079 196.658 52.3079C196.868 52.908 197.204 53.4567 197.643 53.9176C198.082 54.3784 198.614 54.7409 199.204 54.9808C199.794 55.2208 200.429 55.3328 201.066 55.3095C201.902 55.3876 202.742 55.1944 203.46 54.7585C204.178 54.3225 204.735 53.6672 205.049 52.8899L208.412 52.8899ZM196.442 47.7559H205.066C205.098 47.1866 205.011 46.617 204.812 46.0827C204.613 45.5484 204.304 45.061 203.907 44.651C203.51 44.2411 203.032 43.9174 202.503 43.7004C201.975 43.4834 201.407 43.3777 200.835 43.3899C200.258 43.3865 199.687 43.497 199.153 43.715C198.62 43.933 198.135 44.2542 197.726 44.6601C197.318 45.066 196.994 45.5486 196.774 46.0799C196.553 46.6113 196.44 47.1809 196.442 47.7559V47.7559Z"
            fill="black"
          />
          <path
            d="M72.0714 16.589C72.8137 16.5359 73.5586 16.6477 74.2524 16.9165C74.9461 17.1852 75.5714 17.6041 76.0832 18.143C76.5949 18.6819 76.9803 19.3273 77.2117 20.0327C77.443 20.7381 77.5144 21.486 77.4208 22.2222C77.4208 25.8441 75.4578 27.926 72.0714 27.926H67.9648V16.589H72.0714ZM69.7306 26.3227H71.8741C72.4046 26.3544 72.9354 26.2676 73.428 26.0689C73.9206 25.8701 74.3626 25.5643 74.7217 25.1737C75.0809 24.7831 75.3482 24.3176 75.5041 23.811C75.6601 23.3045 75.7008 22.7696 75.6234 22.2453C75.6952 21.7232 75.6504 21.1916 75.492 20.6887C75.3336 20.1859 75.0657 19.7242 74.7073 19.3367C74.349 18.9492 73.9092 18.6456 73.4194 18.4475C72.9295 18.2495 72.4019 18.1619 71.8741 18.1911H69.7306V26.3227Z"
            fill="black"
          />
          <path
            d="M79.4156 23.6442C79.3617 23.082 79.4262 22.5147 79.605 21.9787C79.7838 21.4428 80.0729 20.9501 80.4539 20.5321C80.8348 20.1141 81.2992 19.7802 81.8172 19.5516C82.3351 19.3231 82.8953 19.2051 83.4617 19.2051C84.0281 19.2051 84.5882 19.3231 85.1062 19.5516C85.6242 19.7802 86.0885 20.1141 86.4695 20.5321C86.8504 20.9501 87.1396 21.4428 87.3184 21.9787C87.4972 22.5147 87.5617 23.082 87.5077 23.6442C87.5627 24.207 87.4989 24.7751 87.3206 25.3119C87.1423 25.8487 86.8533 26.3423 86.4723 26.7611C86.0912 27.1799 85.6265 27.5146 85.108 27.7436C84.5895 27.9726 84.0287 28.0909 83.4617 28.0909C82.8946 28.0909 82.3338 27.9726 81.8153 27.7436C81.2968 27.5146 80.8321 27.1799 80.4511 26.7611C80.07 26.3423 79.781 25.8487 79.6027 25.3119C79.4244 24.7751 79.3607 24.207 79.4156 23.6442ZM85.7661 23.6442C85.7661 21.7897 84.9307 20.7051 83.4645 20.7051C81.9927 20.7051 81.1647 21.7897 81.1647 23.6442C81.1647 25.5136 81.9927 26.5898 83.4645 26.5898C84.9307 26.5897 85.7661 25.5062 85.7661 23.6442H85.7661Z"
            fill="black"
          />
          <path
            d="M98.2643 27.9257H96.5079L94.7346 21.6245H94.6007L92.8349 27.9257H91.0951L88.7302 19.3701H90.4476L91.9846 25.8985H92.1111L93.875 19.3701H95.4994L97.2633 25.8985H97.3973L98.9267 19.3701H100.62L98.2643 27.9257Z"
            fill="black"
          />
          <path
            d="M102.609 19.3701H104.239V20.7292H104.366C104.58 20.2411 104.942 19.8319 105.401 19.5586C105.86 19.2854 106.393 19.1618 106.926 19.205C107.343 19.1737 107.763 19.2364 108.152 19.3886C108.542 19.5408 108.893 19.7785 109.178 20.0841C109.463 20.3897 109.676 20.7554 109.8 21.154C109.924 21.5527 109.957 21.9741 109.896 22.3871V27.9256H108.202V22.8111C108.202 21.4362 107.603 20.7524 106.351 20.7524C106.068 20.7393 105.785 20.7874 105.522 20.8934C105.258 20.9995 105.022 21.1611 104.827 21.367C104.632 21.5729 104.485 21.8184 104.394 22.0865C104.304 22.3547 104.272 22.6392 104.302 22.9205V27.9257H102.609L102.609 19.3701Z"
            fill="black"
          />
          <path
            d="M112.594 16.0303H114.287V27.9257H112.594V16.0303Z"
            fill="black"
          />
          <path
            d="M116.64 23.6444C116.586 23.0821 116.651 22.5148 116.83 21.9788C117.009 21.4429 117.298 20.9501 117.679 20.5321C118.06 20.1141 118.524 19.7802 119.042 19.5517C119.56 19.3231 120.12 19.2051 120.687 19.2051C121.253 19.2051 121.813 19.3231 122.331 19.5517C122.849 19.7802 123.314 20.1141 123.695 20.5321C124.076 20.9501 124.365 21.4429 124.544 21.9788C124.723 22.5148 124.787 23.0821 124.733 23.6444C124.788 24.2072 124.724 24.7753 124.546 25.3121C124.368 25.8489 124.079 26.3425 123.697 26.7613C123.316 27.1801 122.852 27.5147 122.333 27.7437C121.815 27.9727 121.254 28.091 120.687 28.091C120.12 28.091 119.559 27.9727 119.04 27.7437C118.522 27.5147 118.057 27.1801 117.676 26.7613C117.295 26.3425 117.006 25.8489 116.827 25.3121C116.649 24.7753 116.585 24.2072 116.64 23.6444ZM122.991 23.6444C122.991 21.7898 122.155 20.7053 120.689 20.7053C119.217 20.7053 118.389 21.7898 118.389 23.6444C118.389 25.5138 119.217 26.5899 120.689 26.5899C122.155 26.5899 122.991 25.5063 122.991 23.6444H122.991Z"
            fill="black"
          />
          <path
            d="M126.516 25.5062C126.516 23.9662 127.666 23.0784 129.707 22.9522L132.031 22.8186V22.0801C132.031 21.1765 131.432 20.6663 130.274 20.6663C129.329 20.6663 128.674 21.0123 128.486 21.6172H126.847C127.02 20.1476 128.406 19.2051 130.353 19.2051C132.504 19.2051 133.717 20.2729 133.717 22.0801V27.9258H132.087V26.7234H131.953C131.681 27.1547 131.299 27.5063 130.846 27.7423C130.394 27.9783 129.886 28.0903 129.376 28.0667C129.016 28.1041 128.652 28.0658 128.307 27.9542C127.963 27.8427 127.646 27.6605 127.376 27.4192C127.107 27.178 126.891 26.8831 126.743 26.5536C126.595 26.2241 126.517 25.8673 126.516 25.5062ZM132.031 24.7752V24.0599L129.936 24.1935C128.754 24.2723 128.218 24.6731 128.218 25.4274C128.218 26.1974 128.888 26.6455 129.809 26.6455C130.079 26.6727 130.352 26.6455 130.611 26.5656C130.87 26.4856 131.11 26.3545 131.318 26.18C131.525 26.0055 131.695 25.7912 131.817 25.5498C131.94 25.3085 132.013 25.0451 132.031 24.7752Z"
            fill="black"
          />
          <path
            d="M135.942 23.6442C135.942 20.9408 137.336 19.2282 139.504 19.2282C140.04 19.2036 140.572 19.3316 141.038 19.5974C141.504 19.8632 141.884 20.2558 142.135 20.7292H142.261V16.0303H143.954V27.9257H142.332V26.574H142.198C141.928 27.0442 141.535 27.4319 141.06 27.695C140.585 27.9581 140.047 28.0866 139.504 28.0667C137.321 28.0668 135.942 26.3541 135.942 23.6442ZM137.691 23.6442C137.691 25.4589 138.549 26.5508 139.984 26.5508C141.411 26.5508 142.293 25.4431 142.293 23.6516C142.293 21.8685 141.402 20.745 139.984 20.745C138.559 20.745 137.691 21.8444 137.691 23.6442H137.691Z"
            fill="black"
          />
          <path
            d="M150.959 23.6442C150.905 23.082 150.97 22.5147 151.149 21.9787C151.327 21.4428 151.617 20.9501 151.998 20.5321C152.379 20.1141 152.843 19.7802 153.361 19.5516C153.879 19.3231 154.439 19.2051 155.005 19.2051C155.572 19.2051 156.132 19.3231 156.65 19.5516C157.168 19.7802 157.632 20.1141 158.013 20.5321C158.394 20.9501 158.683 21.4428 158.862 21.9787C159.041 22.5147 159.105 23.082 159.051 23.6442C159.106 24.207 159.043 24.7751 158.864 25.3119C158.686 25.8487 158.397 26.3423 158.016 26.7611C157.635 27.1799 157.17 27.5146 156.652 27.7436C156.133 27.9726 155.572 28.0909 155.005 28.0909C154.438 28.0909 153.877 27.9726 153.359 27.7436C152.841 27.5146 152.376 27.1799 151.995 26.7611C151.614 26.3423 151.325 25.8487 151.146 25.3119C150.968 24.7751 150.904 24.207 150.959 23.6442ZM157.31 23.6442C157.31 21.7897 156.474 20.7051 155.008 20.7051C153.536 20.7051 152.708 21.7897 152.708 23.6442C152.708 25.5136 153.536 26.5898 155.008 26.5898C156.474 26.5897 157.31 25.5062 157.31 23.6442Z"
            fill="black"
          />
          <path
            d="M161.323 19.3701H162.953V20.7292H163.08C163.294 20.2411 163.656 19.8319 164.115 19.5586C164.574 19.2854 165.107 19.1618 165.64 19.205C166.057 19.1737 166.477 19.2364 166.866 19.3886C167.256 19.5408 167.607 19.7785 167.892 20.0841C168.177 20.3897 168.39 20.7554 168.514 21.154C168.638 21.5527 168.671 21.9741 168.61 22.3871V27.9256H166.916V22.8111C166.916 21.4362 166.317 20.7524 165.065 20.7524C164.782 20.7393 164.499 20.7874 164.236 20.8934C163.973 20.9995 163.736 21.1611 163.541 21.367C163.347 21.5729 163.199 21.8184 163.108 22.0865C163.018 22.3547 162.986 22.6392 163.016 22.9205V27.9257H161.323V19.3701Z"
            fill="black"
          />
          <path
            d="M178.177 17.2402V19.4093H180.036V20.8315H178.177V25.2308C178.177 26.127 178.547 26.5194 179.39 26.5194C179.606 26.5187 179.822 26.5057 180.036 26.4805V27.8869C179.732 27.9411 179.424 27.97 179.115 27.9732C177.232 27.9732 176.482 27.3126 176.482 25.6631V20.8314H175.12V19.4092H176.482V17.2402H178.177Z"
            fill="black"
          />
          <path
            d="M182.349 16.0303H184.028V20.745H184.162C184.387 20.2523 184.759 19.8408 185.227 19.5665C185.695 19.2923 186.237 19.1686 186.778 19.2124C187.193 19.1899 187.608 19.259 187.994 19.4149C188.379 19.5708 188.725 19.8096 189.007 20.1142C189.29 20.4188 189.501 20.7818 189.626 21.1773C189.752 21.5727 189.788 21.9909 189.732 22.402V27.9257H188.038V22.8185C188.038 21.4519 187.399 20.7598 186.203 20.7598C185.912 20.736 185.619 20.7759 185.345 20.8767C185.071 20.9775 184.823 21.1368 184.617 21.3434C184.411 21.55 184.253 21.799 184.154 22.0728C184.055 22.3467 184.017 22.6389 184.043 22.9289V27.9257H182.349L182.349 16.0303Z"
            fill="black"
          />
          <path
            d="M199.605 25.6156C199.375 26.3975 198.877 27.0739 198.197 27.5264C197.518 27.9789 196.7 28.1787 195.888 28.0908C195.323 28.1057 194.761 27.9977 194.242 27.7744C193.722 27.5511 193.258 27.2178 192.881 26.7977C192.504 26.3775 192.223 25.8805 192.058 25.3413C191.893 24.802 191.847 24.2334 191.925 23.6748C191.849 23.1145 191.896 22.5446 192.06 22.0038C192.225 21.4629 192.505 20.9636 192.88 20.5398C193.255 20.116 193.717 19.7774 194.234 19.5471C194.752 19.3168 195.313 19.2002 195.88 19.205C198.268 19.205 199.708 20.8314 199.708 23.518V24.1071H193.649V24.2018C193.623 24.5157 193.662 24.8318 193.766 25.1296C193.869 25.4274 194.033 25.7005 194.248 25.9312C194.463 26.162 194.724 26.3454 195.015 26.4696C195.305 26.5939 195.618 26.6563 195.934 26.6528C196.339 26.7013 196.75 26.6285 197.113 26.4438C197.477 26.2591 197.777 25.9708 197.975 25.6156L199.605 25.6156ZM193.649 22.8584H197.983C198.004 22.5712 197.965 22.2828 197.868 22.0117C197.771 21.7405 197.618 21.4926 197.419 21.2839C197.22 21.0751 196.98 20.9102 196.713 20.7997C196.446 20.6891 196.16 20.6354 195.871 20.642C195.578 20.6384 195.287 20.6932 195.016 20.8033C194.745 20.9134 194.499 21.0765 194.292 21.2831C194.084 21.4896 193.921 21.7354 193.811 22.006C193.7 22.2765 193.646 22.5664 193.649 22.8584H193.649Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_57531_51309">
            <rect width="228" height="76" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

export function AppStoreNewNoLink() {
  return (
    <a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="228"
        height="76"
        viewBox="0 0 228 76"
        fill="none"
      >
        <g clipPath="url(#clip0_57531_51309)">
          <path
            d="M209.844 2.85936e-06H18.1667C17.4681 2.85936e-06 16.7778 2.85948e-06 16.0809 0.00380286C15.4976 0.00760286 14.9189 0.0186419 14.33 0.0279329C13.0506 0.0429463 11.7742 0.155188 10.5119 0.363682C9.25139 0.576715 8.03036 0.978343 6.89011 1.55498C5.75125 2.1365 4.71064 2.89212 3.80604 3.79444C2.89668 4.69421 2.13867 5.73423 1.56114 6.87451C0.98201 8.01251 0.580442 9.23199 0.370302 10.4908C0.158164 11.7481 0.0440101 13.0198 0.0288658 14.2946C0.0111843 14.8772 0.00931708 15.4616 0 16.0442V59.9613C0.00931708 60.5513 0.0111843 61.1228 0.0288658 61.713C0.0440148 62.9877 0.158168 64.2594 0.370302 65.5166C0.579862 66.7761 0.981453 67.9963 1.56114 69.1347C2.1384 70.2713 2.89653 71.3071 3.80604 72.2019C4.70721 73.1082 5.74849 73.8644 6.89011 74.4414C8.03035 75.0196 9.25125 75.4236 10.5119 75.64C11.7744 75.8468 13.0507 75.9591 14.33 75.9759C14.9189 75.9888 15.4976 75.9962 16.0809 75.9962C16.7777 76 17.4681 76 18.1667 76H209.844C210.528 76 211.224 76 211.909 75.9962C212.489 75.9962 213.085 75.9888 213.665 75.9759C214.942 75.96 216.216 75.8477 217.476 75.64C218.741 75.4221 219.966 75.0182 221.112 74.4414C222.252 73.864 223.293 73.1079 224.193 72.2019C225.1 71.3036 225.86 70.2686 226.444 69.1347C227.02 67.9955 227.418 66.7754 227.624 65.5166C227.837 64.2592 227.955 62.9878 227.978 61.713C227.985 61.1228 227.985 60.5513 227.985 59.9613C228 59.2711 228 58.5846 228 57.8831V18.1186C228 17.4228 228 16.7326 227.985 16.0442C227.985 15.4616 227.985 14.8772 227.978 14.2945C227.955 13.0196 227.837 11.7482 227.624 10.4907C227.417 9.23264 227.019 8.01326 226.444 6.87443C225.268 4.58888 223.403 2.72845 221.112 1.55481C219.966 0.979581 218.741 0.57806 217.476 0.363511C216.216 0.154098 214.942 0.0418176 213.665 0.0276669C213.085 0.0183949 212.489 0.00726086 211.909 0.00355586C211.224 -0.000244141 210.528 -0.000244141 209.844 -0.000244141V2.85936e-06Z"
            fill="#181716"
          />
          <path
            d="M16.0904 74.3374C15.5099 74.3374 14.9434 74.3299 14.3674 74.317C13.1743 74.3015 11.9839 74.1979 10.8061 74.0071C9.70786 73.8185 8.64394 73.4677 7.64947 72.9661C6.66411 72.4688 5.76538 71.8166 4.98771 71.0346C4.19879 70.2618 3.54198 69.3655 3.0433 68.3812C2.53913 67.3906 2.19021 66.3287 2.00871 65.2326C1.81269 64.0548 1.70664 62.8639 1.69147 61.6701C1.67939 61.2693 1.66357 59.9352 1.66357 59.9352V16.0441C1.66357 16.0441 1.68042 14.7304 1.69156 14.3445C1.70609 13.1526 1.81153 11.9635 2.00695 10.7875C2.1888 9.68843 2.53798 8.62348 3.04243 7.62955C3.53927 6.64595 4.19244 5.74899 4.97661 4.97345C5.75989 4.19053 6.6615 3.535 7.64853 3.03075C8.64072 2.53083 9.70263 2.18245 10.7987 1.99727C11.9803 1.80454 13.1748 1.70035 14.3721 1.68555L16.0914 1.66235H211.9L213.639 1.68648C214.826 1.70054 216.01 1.80381 217.18 1.9954C218.287 2.18291 219.36 2.53372 220.364 3.03632C222.341 4.05253 223.95 5.66026 224.965 7.63419C225.462 8.62125 225.806 9.67752 225.985 10.7671C226.183 11.9527 226.294 13.1512 226.316 14.3528C226.322 14.8909 226.322 15.4689 226.322 16.0441C226.337 16.7566 226.337 17.4348 226.337 18.1185V57.883C226.337 58.5732 226.337 59.2468 226.322 59.9259C226.322 60.5437 226.322 61.1097 226.314 61.6923C226.292 62.8725 226.183 64.0495 225.989 65.2139C225.811 66.318 225.464 67.3881 224.96 68.3869C224.458 69.3605 223.805 70.2491 223.025 71.0198C222.246 71.806 221.346 72.462 220.358 72.9625C219.358 73.4679 218.286 73.8201 217.18 74.0071C216.003 74.199 214.812 74.3026 213.619 74.317C213.061 74.3299 212.476 74.3374 211.909 74.3374L209.844 74.3412L16.0904 74.3374Z"
            fill="#FFFFF2"
          />
          <path
            d="M47.6267 37.7898C47.648 36.1407 48.0873 34.5237 48.9037 33.0894C49.72 31.6551 50.887 30.4499 52.2959 29.5862C51.4009 28.3115 50.2201 27.2624 48.8473 26.5224C47.4746 25.7824 45.9478 25.3717 44.388 25.323C41.0609 24.9748 37.8353 27.3084 36.1398 27.3084C34.4116 27.3084 31.8011 25.3576 28.9901 25.4153C27.1719 25.4739 25.3999 26.0011 23.8467 26.9457C22.2936 27.8902 21.0123 29.2198 20.1277 30.805C16.2959 37.4208 19.1541 47.1438 22.8247 52.4916C24.6612 55.1102 26.8076 58.0353 29.6161 57.9316C32.3643 57.8179 33.3907 56.184 36.7081 56.184C39.9947 56.184 40.9577 57.9316 43.8231 57.8656C46.7722 57.8179 48.6301 55.2354 50.4023 52.5919C51.7218 50.7261 52.7372 48.6639 53.4108 46.4818C51.6975 45.7591 50.2354 44.5496 49.2068 43.0039C48.1783 41.4583 47.6287 39.6449 47.6267 37.7898Z"
            fill="black"
          />
          <path
            d="M42.2148 21.8057C43.8227 19.8809 44.6149 17.4068 44.423 14.9089C41.9665 15.1662 39.6973 16.337 38.0677 18.188C37.2709 19.0923 36.6607 20.1442 36.2718 21.2838C35.883 22.4234 35.7232 23.6282 35.8016 24.8294C37.0303 24.8421 38.2459 24.5765 39.3567 24.0527C40.4676 23.529 41.4448 22.7607 42.2148 21.8057Z"
            fill="black"
          />
          <path
            d="M80.599 51.5651H71.5803L69.4144 57.9423H65.5945L74.1369 34.3481H78.1057L86.648 57.9423H82.7629L80.599 51.5651ZM72.5143 48.6223H79.6632L76.139 38.2725H76.0403L72.5143 48.6223Z"
            fill="black"
          />
          <path
            d="M105.097 49.3422C105.097 54.6879 102.227 58.1223 97.8976 58.1223C96.8007 58.1795 95.7099 57.9276 94.75 57.3953C93.7901 56.8631 93 56.072 92.47 55.1127H92.388V63.633H88.8472V40.7403H92.2745V43.6014H92.3397C92.894 42.6467 93.6976 41.8598 94.6648 41.3243C95.632 40.7888 96.7267 40.5247 97.8324 40.5603C102.211 40.5603 105.097 44.0115 105.097 49.3422ZM101.457 49.3422C101.457 45.8596 99.6522 43.5699 96.8984 43.5699C94.1929 43.5699 92.3732 45.9078 92.3732 49.3422C92.3732 52.8083 94.1929 55.1295 96.8984 55.1295C99.6522 55.1295 101.457 52.8565 101.457 49.3422H101.457Z"
            fill="black"
          />
          <path
            d="M124.084 49.3422C124.084 54.6879 121.214 58.1223 116.884 58.1223C115.787 58.1795 114.697 57.9276 113.737 57.3953C112.777 56.8631 111.987 56.072 111.457 55.1127H111.375V63.633H107.834V40.7403H111.261V43.6014H111.326C111.881 42.6467 112.684 41.8598 113.651 41.3243C114.619 40.7888 115.713 40.5247 116.819 40.5603C121.197 40.5603 124.084 44.0115 124.084 49.3422ZM120.444 49.3422C120.444 45.8596 118.639 43.5699 115.885 43.5699C113.18 43.5699 111.36 45.9078 111.36 49.3422C111.36 52.8083 113.18 55.1295 115.885 55.1295C118.639 55.1295 120.444 52.8565 120.444 49.3422Z"
            fill="black"
          />
          <path
            d="M136.63 51.3685C136.893 53.7083 139.172 55.2445 142.287 55.2445C145.271 55.2445 147.419 53.7082 147.419 51.5985C147.419 49.7671 146.123 48.6706 143.057 47.9191L139.991 47.1825C135.646 46.136 133.629 44.1098 133.629 40.8219C133.629 36.751 137.187 33.9548 142.237 33.9548C147.238 33.9548 150.665 36.751 150.781 40.8219H147.206C146.992 38.4673 145.041 37.0461 142.188 37.0461C139.336 37.0461 137.384 38.4841 137.384 40.577C137.384 42.2451 138.631 43.2266 141.68 43.978L144.287 44.6163C149.141 45.7611 151.157 47.7057 151.157 51.1568C151.157 55.571 147.632 58.3356 142.024 58.3356C136.777 58.3356 133.234 55.6359 133.006 51.3683L136.63 51.3685Z"
            fill="black"
          />
          <path
            d="M158.802 36.6694V40.7403H162.082V43.5365H158.802V53.0198C158.802 54.4931 159.459 55.1796 160.901 55.1796C161.29 55.1728 161.679 55.1455 162.066 55.0979V57.8774C161.417 57.9982 160.758 58.0529 160.099 58.0407C156.606 58.0407 155.244 56.7326 155.244 53.3964V43.5365H152.736V40.7403H155.244V36.6694H158.802Z"
            fill="black"
          />
          <path
            d="M163.98 49.3423C163.98 43.9299 167.177 40.5288 172.162 40.5288C177.163 40.5288 180.345 43.9298 180.345 49.3423C180.345 54.7695 177.18 58.1558 172.162 58.1558C167.145 58.1558 163.98 54.7695 163.98 49.3423ZM176.737 49.3423C176.737 45.6295 175.031 43.4382 172.162 43.4382C169.292 43.4382 167.586 45.6462 167.586 49.3423C167.586 53.0699 169.292 55.2445 172.162 55.2445C175.031 55.2445 176.737 53.0699 176.737 49.3423H176.737Z"
            fill="black"
          />
          <path
            d="M183.264 40.7404H186.642V43.6683H186.723C186.952 42.7538 187.489 41.9457 188.245 41.3797C189 40.8137 189.928 40.5244 190.873 40.5604C191.281 40.5589 191.688 40.6031 192.086 40.6921V43.9948C191.571 43.8379 191.033 43.7658 190.495 43.7815C189.981 43.7607 189.468 43.8511 188.992 44.0466C188.516 44.2421 188.088 44.538 187.737 44.914C187.387 45.29 187.122 45.7371 186.961 46.2248C186.8 46.7125 186.747 47.2291 186.805 47.7392V57.9424H183.264L183.264 40.7404Z"
            fill="black"
          />
          <path
            d="M208.412 52.8899C207.935 56.0127 204.886 58.1558 200.984 58.1558C195.966 58.1558 192.851 54.8029 192.851 49.4239C192.851 44.0282 195.982 40.5288 200.835 40.5288C205.608 40.5288 208.609 43.7981 208.609 49.0139V50.2236H196.425V50.437C196.369 51.0701 196.448 51.7079 196.658 52.3079C196.868 52.908 197.204 53.4567 197.643 53.9176C198.082 54.3784 198.614 54.7409 199.204 54.9808C199.794 55.2208 200.429 55.3328 201.066 55.3095C201.902 55.3876 202.742 55.1944 203.46 54.7585C204.178 54.3225 204.735 53.6672 205.049 52.8899L208.412 52.8899ZM196.442 47.7559H205.066C205.098 47.1866 205.011 46.617 204.812 46.0827C204.613 45.5484 204.304 45.061 203.907 44.651C203.51 44.2411 203.032 43.9174 202.503 43.7004C201.975 43.4834 201.407 43.3777 200.835 43.3899C200.258 43.3865 199.687 43.497 199.153 43.715C198.62 43.933 198.135 44.2542 197.726 44.6601C197.318 45.066 196.994 45.5486 196.774 46.0799C196.553 46.6113 196.44 47.1809 196.442 47.7559V47.7559Z"
            fill="black"
          />
          <path
            d="M72.0714 16.589C72.8137 16.5359 73.5586 16.6477 74.2524 16.9165C74.9461 17.1852 75.5714 17.6041 76.0832 18.143C76.5949 18.6819 76.9803 19.3273 77.2117 20.0327C77.443 20.7381 77.5144 21.486 77.4208 22.2222C77.4208 25.8441 75.4578 27.926 72.0714 27.926H67.9648V16.589H72.0714ZM69.7306 26.3227H71.8741C72.4046 26.3544 72.9354 26.2676 73.428 26.0689C73.9206 25.8701 74.3626 25.5643 74.7217 25.1737C75.0809 24.7831 75.3482 24.3176 75.5041 23.811C75.6601 23.3045 75.7008 22.7696 75.6234 22.2453C75.6952 21.7232 75.6504 21.1916 75.492 20.6887C75.3336 20.1859 75.0657 19.7242 74.7073 19.3367C74.349 18.9492 73.9092 18.6456 73.4194 18.4475C72.9295 18.2495 72.4019 18.1619 71.8741 18.1911H69.7306V26.3227Z"
            fill="black"
          />
          <path
            d="M79.4156 23.6442C79.3617 23.082 79.4262 22.5147 79.605 21.9787C79.7838 21.4428 80.0729 20.9501 80.4539 20.5321C80.8348 20.1141 81.2992 19.7802 81.8172 19.5516C82.3351 19.3231 82.8953 19.2051 83.4617 19.2051C84.0281 19.2051 84.5882 19.3231 85.1062 19.5516C85.6242 19.7802 86.0885 20.1141 86.4695 20.5321C86.8504 20.9501 87.1396 21.4428 87.3184 21.9787C87.4972 22.5147 87.5617 23.082 87.5077 23.6442C87.5627 24.207 87.4989 24.7751 87.3206 25.3119C87.1423 25.8487 86.8533 26.3423 86.4723 26.7611C86.0912 27.1799 85.6265 27.5146 85.108 27.7436C84.5895 27.9726 84.0287 28.0909 83.4617 28.0909C82.8946 28.0909 82.3338 27.9726 81.8153 27.7436C81.2968 27.5146 80.8321 27.1799 80.4511 26.7611C80.07 26.3423 79.781 25.8487 79.6027 25.3119C79.4244 24.7751 79.3607 24.207 79.4156 23.6442ZM85.7661 23.6442C85.7661 21.7897 84.9307 20.7051 83.4645 20.7051C81.9927 20.7051 81.1647 21.7897 81.1647 23.6442C81.1647 25.5136 81.9927 26.5898 83.4645 26.5898C84.9307 26.5897 85.7661 25.5062 85.7661 23.6442H85.7661Z"
            fill="black"
          />
          <path
            d="M98.2643 27.9257H96.5079L94.7346 21.6245H94.6007L92.8349 27.9257H91.0951L88.7302 19.3701H90.4476L91.9846 25.8985H92.1111L93.875 19.3701H95.4994L97.2633 25.8985H97.3973L98.9267 19.3701H100.62L98.2643 27.9257Z"
            fill="black"
          />
          <path
            d="M102.609 19.3701H104.239V20.7292H104.366C104.58 20.2411 104.942 19.8319 105.401 19.5586C105.86 19.2854 106.393 19.1618 106.926 19.205C107.343 19.1737 107.763 19.2364 108.152 19.3886C108.542 19.5408 108.893 19.7785 109.178 20.0841C109.463 20.3897 109.676 20.7554 109.8 21.154C109.924 21.5527 109.957 21.9741 109.896 22.3871V27.9256H108.202V22.8111C108.202 21.4362 107.603 20.7524 106.351 20.7524C106.068 20.7393 105.785 20.7874 105.522 20.8934C105.258 20.9995 105.022 21.1611 104.827 21.367C104.632 21.5729 104.485 21.8184 104.394 22.0865C104.304 22.3547 104.272 22.6392 104.302 22.9205V27.9257H102.609L102.609 19.3701Z"
            fill="black"
          />
          <path
            d="M112.594 16.0303H114.287V27.9257H112.594V16.0303Z"
            fill="black"
          />
          <path
            d="M116.64 23.6444C116.586 23.0821 116.651 22.5148 116.83 21.9788C117.009 21.4429 117.298 20.9501 117.679 20.5321C118.06 20.1141 118.524 19.7802 119.042 19.5517C119.56 19.3231 120.12 19.2051 120.687 19.2051C121.253 19.2051 121.813 19.3231 122.331 19.5517C122.849 19.7802 123.314 20.1141 123.695 20.5321C124.076 20.9501 124.365 21.4429 124.544 21.9788C124.723 22.5148 124.787 23.0821 124.733 23.6444C124.788 24.2072 124.724 24.7753 124.546 25.3121C124.368 25.8489 124.079 26.3425 123.697 26.7613C123.316 27.1801 122.852 27.5147 122.333 27.7437C121.815 27.9727 121.254 28.091 120.687 28.091C120.12 28.091 119.559 27.9727 119.04 27.7437C118.522 27.5147 118.057 27.1801 117.676 26.7613C117.295 26.3425 117.006 25.8489 116.827 25.3121C116.649 24.7753 116.585 24.2072 116.64 23.6444ZM122.991 23.6444C122.991 21.7898 122.155 20.7053 120.689 20.7053C119.217 20.7053 118.389 21.7898 118.389 23.6444C118.389 25.5138 119.217 26.5899 120.689 26.5899C122.155 26.5899 122.991 25.5063 122.991 23.6444H122.991Z"
            fill="black"
          />
          <path
            d="M126.516 25.5062C126.516 23.9662 127.666 23.0784 129.707 22.9522L132.031 22.8186V22.0801C132.031 21.1765 131.432 20.6663 130.274 20.6663C129.329 20.6663 128.674 21.0123 128.486 21.6172H126.847C127.02 20.1476 128.406 19.2051 130.353 19.2051C132.504 19.2051 133.717 20.2729 133.717 22.0801V27.9258H132.087V26.7234H131.953C131.681 27.1547 131.299 27.5063 130.846 27.7423C130.394 27.9783 129.886 28.0903 129.376 28.0667C129.016 28.1041 128.652 28.0658 128.307 27.9542C127.963 27.8427 127.646 27.6605 127.376 27.4192C127.107 27.178 126.891 26.8831 126.743 26.5536C126.595 26.2241 126.517 25.8673 126.516 25.5062ZM132.031 24.7752V24.0599L129.936 24.1935C128.754 24.2723 128.218 24.6731 128.218 25.4274C128.218 26.1974 128.888 26.6455 129.809 26.6455C130.079 26.6727 130.352 26.6455 130.611 26.5656C130.87 26.4856 131.11 26.3545 131.318 26.18C131.525 26.0055 131.695 25.7912 131.817 25.5498C131.94 25.3085 132.013 25.0451 132.031 24.7752Z"
            fill="black"
          />
          <path
            d="M135.942 23.6442C135.942 20.9408 137.336 19.2282 139.504 19.2282C140.04 19.2036 140.572 19.3316 141.038 19.5974C141.504 19.8632 141.884 20.2558 142.135 20.7292H142.261V16.0303H143.954V27.9257H142.332V26.574H142.198C141.928 27.0442 141.535 27.4319 141.06 27.695C140.585 27.9581 140.047 28.0866 139.504 28.0667C137.321 28.0668 135.942 26.3541 135.942 23.6442ZM137.691 23.6442C137.691 25.4589 138.549 26.5508 139.984 26.5508C141.411 26.5508 142.293 25.4431 142.293 23.6516C142.293 21.8685 141.402 20.745 139.984 20.745C138.559 20.745 137.691 21.8444 137.691 23.6442H137.691Z"
            fill="black"
          />
          <path
            d="M150.959 23.6442C150.905 23.082 150.97 22.5147 151.149 21.9787C151.327 21.4428 151.617 20.9501 151.998 20.5321C152.379 20.1141 152.843 19.7802 153.361 19.5516C153.879 19.3231 154.439 19.2051 155.005 19.2051C155.572 19.2051 156.132 19.3231 156.65 19.5516C157.168 19.7802 157.632 20.1141 158.013 20.5321C158.394 20.9501 158.683 21.4428 158.862 21.9787C159.041 22.5147 159.105 23.082 159.051 23.6442C159.106 24.207 159.043 24.7751 158.864 25.3119C158.686 25.8487 158.397 26.3423 158.016 26.7611C157.635 27.1799 157.17 27.5146 156.652 27.7436C156.133 27.9726 155.572 28.0909 155.005 28.0909C154.438 28.0909 153.877 27.9726 153.359 27.7436C152.841 27.5146 152.376 27.1799 151.995 26.7611C151.614 26.3423 151.325 25.8487 151.146 25.3119C150.968 24.7751 150.904 24.207 150.959 23.6442ZM157.31 23.6442C157.31 21.7897 156.474 20.7051 155.008 20.7051C153.536 20.7051 152.708 21.7897 152.708 23.6442C152.708 25.5136 153.536 26.5898 155.008 26.5898C156.474 26.5897 157.31 25.5062 157.31 23.6442Z"
            fill="black"
          />
          <path
            d="M161.323 19.3701H162.953V20.7292H163.08C163.294 20.2411 163.656 19.8319 164.115 19.5586C164.574 19.2854 165.107 19.1618 165.64 19.205C166.057 19.1737 166.477 19.2364 166.866 19.3886C167.256 19.5408 167.607 19.7785 167.892 20.0841C168.177 20.3897 168.39 20.7554 168.514 21.154C168.638 21.5527 168.671 21.9741 168.61 22.3871V27.9256H166.916V22.8111C166.916 21.4362 166.317 20.7524 165.065 20.7524C164.782 20.7393 164.499 20.7874 164.236 20.8934C163.973 20.9995 163.736 21.1611 163.541 21.367C163.347 21.5729 163.199 21.8184 163.108 22.0865C163.018 22.3547 162.986 22.6392 163.016 22.9205V27.9257H161.323V19.3701Z"
            fill="black"
          />
          <path
            d="M178.177 17.2402V19.4093H180.036V20.8315H178.177V25.2308C178.177 26.127 178.547 26.5194 179.39 26.5194C179.606 26.5187 179.822 26.5057 180.036 26.4805V27.8869C179.732 27.9411 179.424 27.97 179.115 27.9732C177.232 27.9732 176.482 27.3126 176.482 25.6631V20.8314H175.12V19.4092H176.482V17.2402H178.177Z"
            fill="black"
          />
          <path
            d="M182.349 16.0303H184.028V20.745H184.162C184.387 20.2523 184.759 19.8408 185.227 19.5665C185.695 19.2923 186.237 19.1686 186.778 19.2124C187.193 19.1899 187.608 19.259 187.994 19.4149C188.379 19.5708 188.725 19.8096 189.007 20.1142C189.29 20.4188 189.501 20.7818 189.626 21.1773C189.752 21.5727 189.788 21.9909 189.732 22.402V27.9257H188.038V22.8185C188.038 21.4519 187.399 20.7598 186.203 20.7598C185.912 20.736 185.619 20.7759 185.345 20.8767C185.071 20.9775 184.823 21.1368 184.617 21.3434C184.411 21.55 184.253 21.799 184.154 22.0728C184.055 22.3467 184.017 22.6389 184.043 22.9289V27.9257H182.349L182.349 16.0303Z"
            fill="black"
          />
          <path
            d="M199.605 25.6156C199.375 26.3975 198.877 27.0739 198.197 27.5264C197.518 27.9789 196.7 28.1787 195.888 28.0908C195.323 28.1057 194.761 27.9977 194.242 27.7744C193.722 27.5511 193.258 27.2178 192.881 26.7977C192.504 26.3775 192.223 25.8805 192.058 25.3413C191.893 24.802 191.847 24.2334 191.925 23.6748C191.849 23.1145 191.896 22.5446 192.06 22.0038C192.225 21.4629 192.505 20.9636 192.88 20.5398C193.255 20.116 193.717 19.7774 194.234 19.5471C194.752 19.3168 195.313 19.2002 195.88 19.205C198.268 19.205 199.708 20.8314 199.708 23.518V24.1071H193.649V24.2018C193.623 24.5157 193.662 24.8318 193.766 25.1296C193.869 25.4274 194.033 25.7005 194.248 25.9312C194.463 26.162 194.724 26.3454 195.015 26.4696C195.305 26.5939 195.618 26.6563 195.934 26.6528C196.339 26.7013 196.75 26.6285 197.113 26.4438C197.477 26.2591 197.777 25.9708 197.975 25.6156L199.605 25.6156ZM193.649 22.8584H197.983C198.004 22.5712 197.965 22.2828 197.868 22.0117C197.771 21.7405 197.618 21.4926 197.419 21.2839C197.22 21.0751 196.98 20.9102 196.713 20.7997C196.446 20.6891 196.16 20.6354 195.871 20.642C195.578 20.6384 195.287 20.6932 195.016 20.8033C194.745 20.9134 194.499 21.0765 194.292 21.2831C194.084 21.4896 193.921 21.7354 193.811 22.006C193.7 22.2765 193.646 22.5664 193.649 22.8584H193.649Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_57531_51309">
            <rect width="228" height="76" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

export function AppStoreNewSmall(props: AppstoreProps) {
  return (
    <a
      onClick={() => {
        Mixpanel?.track("App/Play Store link clicked from homepage", {
          type: "AppStore",
        });
      }}
      href={
        props.link
          ? "https://apps.apple.com/app/apple-store/id1520060596?pt=121710263&ct=homepage&mt=8"
          : "https://apps.apple.com/us/app/dimensional-personality-test/id1520060596?utm_source=%22homepage%22"
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="156"
        height="52"
        viewBox="0 0 156 52"
        fill="none"
      >
        <g clipPath="url(#clip0_58750_15848)">
          <path
            d="M143.577 0.000169H12.4299C11.9518 0.000169 11.4795 0.000169 11.0027 0.002769C10.6036 0.005369 10.2077 0.012922 9.80475 0.019279C8.92936 0.0295513 8.05604 0.106348 7.19236 0.249002C6.3299 0.394761 5.49446 0.669559 4.71428 1.0641C3.93507 1.46198 3.22307 1.97899 2.60413 2.59636C1.98194 3.212 1.4633 3.92359 1.06815 4.70378C0.671901 5.48241 0.397144 6.31679 0.253364 7.17809C0.108217 8.03831 0.0301122 8.90845 0.0197503 9.78069C0.00765242 10.1793 0.00637485 10.5792 0 10.9778V41.0263C0.00637485 41.43 0.00765242 41.821 0.0197503 42.2248C0.0301154 43.097 0.10822 43.9671 0.253364 44.8273C0.396747 45.6891 0.67152 46.524 1.06815 47.3029C1.46312 48.0805 1.98184 48.7893 2.60413 49.4015C3.22072 50.0216 3.93318 50.5389 4.71428 50.9338C5.49445 51.3294 6.3298 51.6058 7.19236 51.7538C8.05619 51.8953 8.92942 51.9722 9.80475 51.9837C10.2077 51.9925 10.6036 51.9976 11.0027 51.9976C11.4795 52.0002 11.9519 52.0002 12.4299 52.0002H143.577C144.046 52.0002 144.522 52.0002 144.99 51.9976C145.388 51.9976 145.795 51.9925 146.192 51.9837C147.066 51.9728 147.937 51.8959 148.799 51.7538C149.665 51.6048 150.503 51.3284 151.287 50.9338C152.067 50.5387 152.779 50.0214 153.395 49.4015C154.016 48.7869 154.536 48.0787 154.936 47.3029C155.329 46.5234 155.602 45.6886 155.743 44.8273C155.888 43.967 155.969 43.0971 155.985 42.2248C155.99 41.821 155.99 41.43 155.99 41.0263C156 40.5541 156 40.0844 156 39.6044V12.3971C156 11.9211 156 11.4488 155.99 10.9778C155.99 10.5792 155.99 10.1793 155.985 9.78064C155.969 8.90832 155.888 8.03837 155.743 7.17804C155.601 6.31723 155.329 5.48292 154.936 4.70372C154.131 3.13992 152.855 1.867 151.287 1.06398C150.503 0.670407 149.665 0.395682 148.799 0.248885C147.938 0.105603 147.066 0.0287791 146.192 0.019097C145.795 0.012753 145.388 0.005135 144.99 0.0026C144.522 0 144.046 0 143.577 0V0.000169Z"
            fill="#181716"
          />
          <path
            d="M11.0092 50.8625C10.612 50.8625 10.2244 50.8574 9.8303 50.8485C9.01394 50.8379 8.19947 50.7671 7.3936 50.6365C6.64217 50.5075 5.91423 50.2674 5.23379 49.9243C4.5596 49.5839 3.94468 49.1377 3.4126 48.6027C2.87281 48.0739 2.42341 47.4607 2.08221 46.7872C1.73725 46.1094 1.49852 45.3828 1.37433 44.6329C1.24021 43.827 1.16765 43.0122 1.15727 42.1954C1.149 41.9212 1.13818 41.0083 1.13818 41.0083V10.9776C1.13818 10.9776 1.14971 10.0788 1.15733 9.81469C1.16728 8.99918 1.23941 8.1856 1.37313 7.381C1.49755 6.62898 1.73646 5.90033 2.08161 5.22027C2.42155 4.54728 2.86846 3.93357 3.405 3.40294C3.94093 2.86726 4.55781 2.41874 5.23315 2.07372C5.91202 1.73167 6.63859 1.4933 7.3885 1.3666C8.19702 1.23474 9.01431 1.16345 9.83351 1.15332L11.0098 1.13745H144.984L146.174 1.15396C146.986 1.16358 147.796 1.23424 148.597 1.36533C149.355 1.49362 150.089 1.73365 150.775 2.07753C152.128 2.77283 153.229 3.87286 153.924 5.22344C154.263 5.8988 154.498 6.62151 154.621 7.36704C154.757 8.17824 154.832 8.99821 154.848 9.82041C154.852 10.1886 154.852 10.584 154.852 10.9776C154.862 11.4651 154.862 11.9291 154.862 12.3969V39.6042C154.862 40.0765 154.862 40.5373 154.852 41.002C154.852 41.4247 154.852 41.812 154.847 42.2106C154.831 43.0181 154.757 43.8234 154.624 44.6201C154.502 45.3755 154.265 46.1077 153.92 46.7911C153.576 47.4573 153.129 48.0652 152.596 48.5925C152.063 49.1304 151.447 49.5793 150.772 49.9218C150.087 50.2676 149.354 50.5085 148.597 50.6365C147.791 50.7678 146.977 50.8386 146.16 50.8485C145.778 50.8574 145.379 50.8625 144.99 50.8625L143.577 50.8651L11.0092 50.8625Z"
            fill="#FFFFF2"
          />
          <path
            d="M32.587 25.8562C32.6015 24.7278 32.9021 23.6215 33.4607 22.6401C34.0192 21.6587 34.8177 20.8341 35.7817 20.2431C35.1693 19.371 34.3614 18.6532 33.4221 18.1469C32.4829 17.6405 31.4382 17.3595 30.371 17.3262C28.0945 17.088 25.8876 18.6846 24.7275 18.6846C23.545 18.6846 21.7589 17.3499 19.8356 17.3894C18.5916 17.4294 17.3791 17.7902 16.3165 18.4365C15.2538 19.0827 14.3771 19.9925 13.7719 21.0771C11.1501 25.6037 13.1057 32.2563 15.6172 35.9153C16.8737 37.707 18.3423 39.7083 20.2639 39.6374C22.1443 39.5596 22.8466 38.4417 25.1163 38.4417C27.365 38.4417 28.0239 39.6374 29.9845 39.5922C32.0023 39.5596 33.2735 37.7926 34.486 35.9839C35.3888 34.7073 36.0836 33.2963 36.5445 31.8033C35.3722 31.3088 34.3718 30.4813 33.6681 29.4237C32.9643 28.3661 32.5883 27.1254 32.587 25.8562Z"
            fill="black"
          />
          <path
            d="M28.8836 14.92C29.9838 13.603 30.5258 11.9102 30.3946 10.2012C28.7138 10.3772 27.1612 11.1783 26.0462 12.4447C25.501 13.0634 25.0835 13.7832 24.8174 14.5629C24.5514 15.3427 24.4421 16.167 24.4957 16.9889C25.3364 16.9975 26.1681 16.8158 26.9281 16.4574C27.6882 16.0991 28.3568 15.5734 28.8836 14.92Z"
            fill="black"
          />
          <path
            d="M55.1466 35.2817H48.9759L47.494 39.6451H44.8804L50.7251 23.5017H53.4407L59.2854 39.6451H56.6272L55.1466 35.2817ZM49.615 33.2682H54.5063L52.095 26.1868H52.0275L49.615 33.2682Z"
            fill="black"
          />
          <path
            d="M71.9085 33.7604C71.9085 37.4179 69.9454 39.7678 66.9829 39.7678C66.2324 39.8069 65.4861 39.6346 64.8293 39.2704C64.1726 38.9062 63.6319 38.365 63.2693 37.7086H63.2132V43.5383H60.7905V27.8748H63.1356V29.8324H63.1801C63.5594 29.1792 64.1092 28.6408 64.771 28.2744C65.4328 27.908 66.1818 27.7274 66.9383 27.7517C69.9339 27.7517 71.9085 30.113 71.9085 33.7604ZM69.4183 33.7604C69.4183 31.3775 68.1834 29.8109 66.2992 29.8109C64.4482 29.8109 63.2031 31.4105 63.2031 33.7604C63.2031 36.1319 64.4482 37.7201 66.2992 37.7201C68.1834 37.7201 69.4183 36.1649 69.4183 33.7604H69.4183Z"
            fill="black"
          />
          <path
            d="M84.8999 33.7604C84.8999 37.4179 82.9361 39.7678 79.9736 39.7678C79.2232 39.8069 78.4768 39.6346 77.8201 39.2704C77.1633 38.9062 76.6226 38.365 76.26 37.7086H76.2039V43.5383H73.7812V27.8748H76.1263V29.8324H76.1708C76.5502 29.1792 77.0999 28.6408 77.7617 28.2744C78.4235 27.908 79.1725 27.7274 79.929 27.7517C82.9247 27.7517 84.8999 30.113 84.8999 33.7604ZM82.4091 33.7604C82.4091 31.3775 81.1741 29.8109 79.29 29.8109C77.4389 29.8109 76.1938 31.4105 76.1938 33.7604C76.1938 36.1319 77.4389 37.7201 79.29 37.7201C81.1741 37.7201 82.4091 36.1649 82.4091 33.7604Z"
            fill="black"
          />
          <path
            d="M93.484 35.147C93.6635 36.7479 95.223 37.799 97.3542 37.799C99.3962 37.799 100.865 36.7479 100.865 35.3044C100.865 34.0514 99.9793 33.3011 97.8812 32.7869L95.7832 32.2829C92.8105 31.5669 91.4305 30.1806 91.4305 27.931C91.4305 25.1456 93.8646 23.2324 97.3198 23.2324C100.742 23.2324 103.087 25.1456 103.166 27.931H100.72C100.574 26.3199 99.2383 25.3475 97.2867 25.3475C95.335 25.3475 93.9995 26.3314 93.9995 27.7634C93.9995 28.9047 94.8525 29.5763 96.9391 30.0904L98.7227 30.5271C102.044 31.3104 103.423 32.6409 103.423 35.0022C103.423 38.0224 101.012 39.914 97.1746 39.914C93.5845 39.914 91.1605 38.0668 91.0039 35.1469L93.484 35.147Z"
            fill="black"
          />
          <path
            d="M108.654 25.0901V27.8754H110.899V29.7886H108.654V36.2772C108.654 37.2852 109.104 37.7549 110.09 37.7549C110.357 37.7503 110.623 37.7316 110.887 37.699V39.6008C110.444 39.6834 109.993 39.7209 109.542 39.7125C107.152 39.7125 106.22 38.8175 106.22 36.5349V29.7886H104.504V27.8754H106.22V25.0901H108.654Z"
            fill="black"
          />
          <path
            d="M112.197 33.761C112.197 30.0578 114.384 27.7307 117.795 27.7307C121.217 27.7307 123.394 30.0577 123.394 33.761C123.394 37.4744 121.229 39.7913 117.795 39.7913C114.363 39.7913 112.197 37.4744 112.197 33.761ZM120.926 33.761C120.926 31.2207 119.758 29.7213 117.795 29.7213C115.832 29.7213 114.665 31.2321 114.665 33.761C114.665 36.3115 115.832 37.7994 117.795 37.7994C119.758 37.7994 120.926 36.3115 120.926 33.761H120.926Z"
            fill="black"
          />
          <path
            d="M125.392 27.8752H127.703V29.8785H127.759C127.915 29.2528 128.283 28.6999 128.8 28.3127C129.317 27.9254 129.952 27.7274 130.598 27.752C130.877 27.7511 131.155 27.7813 131.428 27.8422V30.102C131.075 29.9946 130.708 29.9452 130.339 29.956C129.987 29.9417 129.637 30.0036 129.311 30.1374C128.985 30.2711 128.692 30.4736 128.452 30.7308C128.213 30.9881 128.031 31.294 127.921 31.6277C127.811 31.9614 127.775 32.3149 127.815 32.6639V39.645H125.392L125.392 27.8752Z"
            fill="black"
          />
          <path
            d="M142.598 36.1883C142.272 38.3249 140.185 39.7913 137.515 39.7913C134.082 39.7913 131.951 37.4972 131.951 33.8168C131.951 30.1251 134.093 27.7307 137.414 27.7307C140.679 27.7307 142.733 29.9676 142.733 33.5363V34.364H134.396V34.51C134.358 34.9432 134.412 35.3795 134.556 35.7901C134.699 36.2007 134.929 36.5761 135.229 36.8914C135.53 37.2068 135.894 37.4548 136.298 37.6189C136.701 37.7831 137.136 37.8598 137.571 37.8438C138.144 37.8973 138.718 37.7651 139.21 37.4668C139.701 37.1685 140.082 36.7201 140.297 36.1883L142.598 36.1883ZM134.408 32.6755H140.309C140.33 32.2861 140.271 31.8963 140.135 31.5307C139.998 31.1652 139.787 30.8317 139.516 30.5512C139.244 30.2707 138.917 30.0492 138.555 29.9008C138.193 29.7523 137.805 29.68 137.414 29.6883C137.019 29.686 136.628 29.7616 136.263 29.9107C135.898 30.0599 135.566 30.2797 135.286 30.5574C135.007 30.8351 134.786 31.1653 134.635 31.5288C134.484 31.8924 134.407 32.2821 134.408 32.6755V32.6755Z"
            fill="black"
          />
          <path
            d="M49.3126 11.3511C49.8206 11.3147 50.3302 11.3913 50.8049 11.5751C51.2796 11.759 51.7074 12.0456 52.0576 12.4143C52.4077 12.783 52.6714 13.2246 52.8297 13.7073C52.988 14.1899 53.0368 14.7016 52.9728 15.2054C52.9728 17.6835 51.6297 19.108 49.3126 19.108H46.5029V11.3511H49.3126ZM47.7111 18.011H49.1777C49.5406 18.0326 49.9038 17.9733 50.2409 17.8373C50.5779 17.7013 50.8803 17.492 51.1261 17.2248C51.3718 16.9576 51.5547 16.6391 51.6614 16.2925C51.7681 15.9459 51.796 15.5799 51.743 15.2212C51.7921 14.8639 51.7614 14.5002 51.6531 14.1561C51.5447 13.8121 51.3614 13.4962 51.1162 13.2311C50.8711 12.966 50.5701 12.7582 50.235 12.6227C49.8998 12.4872 49.5388 12.4273 49.1777 12.4473H47.7111V18.011Z"
            fill="black"
          />
          <path
            d="M54.3373 16.1784C54.3004 15.7937 54.3445 15.4056 54.4669 15.0389C54.5892 14.6722 54.787 14.335 55.0477 14.0491C55.3084 13.7631 55.6261 13.5346 55.9805 13.3782C56.3349 13.2219 56.7181 13.1411 57.1057 13.1411C57.4932 13.1411 57.8765 13.2219 58.2309 13.3782C58.5853 13.5346 58.903 13.7631 59.1636 14.0491C59.4243 14.335 59.6221 14.6722 59.7445 15.0389C59.8668 15.4056 59.9109 15.7937 59.874 16.1784C59.9116 16.5635 59.868 16.9522 59.746 17.3195C59.624 17.6867 59.4263 18.0245 59.1655 18.311C58.9048 18.5976 58.5869 18.8265 58.2321 18.9832C57.8774 19.1399 57.4937 19.2209 57.1057 19.2209C56.7177 19.2209 56.334 19.1399 55.9792 18.9832C55.6245 18.8265 55.3065 18.5976 55.0458 18.311C54.7851 18.0245 54.5873 17.6867 54.4653 17.3195C54.3433 16.9522 54.2997 16.5635 54.3373 16.1784ZM58.6824 16.1784C58.6824 14.9095 58.1108 14.1675 57.1076 14.1675C56.1006 14.1675 55.5341 14.9095 55.5341 16.1784C55.5341 17.4575 56.1006 18.1938 57.1076 18.1938C58.1108 18.1938 58.6824 17.4524 58.6824 16.1784H58.6824Z"
            fill="black"
          />
          <path
            d="M67.2338 19.1077H66.032L64.8187 14.7964H64.7271L63.5189 19.1077H62.3286L60.7104 13.2539H61.8855L62.9371 17.7207H63.0237L64.2306 13.2539H65.342L66.5489 17.7207H66.6405L67.687 13.2539H68.8455L67.2338 19.1077Z"
            fill="black"
          />
          <path
            d="M70.2066 13.2535H71.3218V14.1835H71.4083C71.5552 13.8495 71.8029 13.5695 72.1169 13.3825C72.4309 13.1956 72.7956 13.111 73.1601 13.1405C73.4458 13.1191 73.7326 13.1621 73.9993 13.2662C74.266 13.3703 74.5058 13.5329 74.701 13.742C74.8961 13.9511 75.0415 14.2013 75.1265 14.4741C75.2115 14.7469 75.2339 15.0352 75.192 15.3178V19.1073H74.0335V15.6079C74.0335 14.6672 73.6235 14.1993 72.7667 14.1993C72.5728 14.1903 72.3792 14.2232 72.1992 14.2958C72.0193 14.3684 71.8572 14.4789 71.724 14.6198C71.5909 14.7607 71.4899 14.9287 71.4279 15.1121C71.366 15.2956 71.3445 15.4903 71.3651 15.6828V19.1073H70.2065L70.2066 13.2535Z"
            fill="black"
          />
          <path
            d="M77.0381 10.9688H78.1966V19.1077H77.0381V10.9688Z"
            fill="black"
          />
          <path
            d="M79.807 16.1785C79.7701 15.7938 79.8143 15.4056 79.9367 15.0389C80.059 14.6722 80.2569 14.3351 80.5176 14.0491C80.7783 13.7631 81.096 13.5346 81.4504 13.3782C81.8048 13.2219 82.1881 13.1411 82.5757 13.1411C82.9632 13.1411 83.3465 13.2219 83.7009 13.3782C84.0554 13.5346 84.3731 13.7631 84.6338 14.0491C84.8945 14.3351 85.0923 14.6722 85.2147 15.0389C85.3371 15.4056 85.3812 15.7938 85.3443 16.1785C85.3819 16.5636 85.3383 16.9523 85.2162 17.3196C85.0942 17.6869 84.8964 18.0246 84.6356 18.3111C84.3749 18.5977 84.0569 18.8266 83.7022 18.9833C83.3474 19.14 82.9637 19.221 82.5757 19.221C82.1877 19.221 81.8039 19.14 81.4492 18.9833C81.0944 18.8266 80.7764 18.5977 80.5157 18.3111C80.2549 18.0246 80.0572 17.6869 79.9351 17.3196C79.8131 16.9523 79.7695 16.5636 79.807 16.1785ZM84.1521 16.1785C84.1521 14.9096 83.5804 14.1676 82.5773 14.1676C81.5702 14.1676 81.0038 14.9096 81.0038 16.1785C81.0038 17.4576 81.5703 18.1939 82.5773 18.1939C83.5805 18.1939 84.1521 17.4525 84.1521 16.1785H84.1521Z"
            fill="black"
          />
          <path
            d="M86.564 17.4524C86.564 16.3987 87.3507 15.7913 88.7473 15.7049L90.3374 15.6135V15.1082C90.3374 14.49 89.9275 14.1409 89.1356 14.1409C88.4889 14.1409 88.0408 14.3776 87.9122 14.7915H86.7906C86.909 13.786 87.8574 13.1411 89.1891 13.1411C90.6608 13.1411 91.4909 13.8717 91.4909 15.1082V19.1079H90.3756V18.2853H90.284C90.0979 18.5803 89.8367 18.8209 89.5269 18.9824C89.2171 19.1439 88.8699 19.2205 88.5207 19.2044C88.2743 19.2299 88.0252 19.2037 87.7896 19.1274C87.5539 19.0511 87.3369 18.9264 87.1526 18.7613C86.9682 18.5963 86.8206 18.3945 86.7192 18.1691C86.6178 17.9436 86.5649 17.6995 86.564 17.4524ZM90.3374 16.9522V16.4628L88.9039 16.5542C88.0955 16.6082 87.7288 16.8824 87.7288 17.3985C87.7288 17.9253 88.1872 18.2319 88.8174 18.2319C89.002 18.2506 89.1886 18.232 89.3659 18.1772C89.5432 18.1225 89.7076 18.0328 89.8495 17.9134C89.9913 17.794 90.1076 17.6474 90.1914 17.4823C90.2752 17.3171 90.3249 17.1369 90.3374 16.9522Z"
            fill="black"
          />
          <path
            d="M93.0137 16.1783C93.0137 14.3286 93.9672 13.1568 95.4504 13.1568C95.8172 13.1399 96.1813 13.2276 96.5001 13.4094C96.8189 13.5913 97.0792 13.8599 97.2505 14.1838H97.3371V10.9688H98.4956V19.1077H97.3855V18.1829H97.2938C97.1092 18.5046 96.8399 18.7698 96.5149 18.9499C96.19 19.1299 95.8219 19.2178 95.4504 19.2042C93.957 19.2042 93.0137 18.0324 93.0137 16.1783ZM94.2104 16.1783C94.2104 17.4199 94.7973 18.167 95.7789 18.167C96.7553 18.167 97.3588 17.4091 97.3588 16.1834C97.3588 14.9634 96.749 14.1946 95.7789 14.1946C94.8037 14.1946 94.2104 14.9468 94.2104 16.1783H94.2104Z"
            fill="black"
          />
          <path
            d="M103.288 16.1784C103.252 15.7937 103.296 15.4056 103.418 15.0389C103.54 14.6722 103.738 14.335 103.999 14.0491C104.26 13.7631 104.577 13.5346 104.932 13.3782C105.286 13.2219 105.669 13.1411 106.057 13.1411C106.444 13.1411 106.828 13.2219 107.182 13.3782C107.536 13.5346 107.854 13.7631 108.115 14.0491C108.375 14.335 108.573 14.6722 108.696 15.0389C108.818 15.4056 108.862 15.7937 108.825 16.1784C108.863 16.5635 108.819 16.9522 108.697 17.3195C108.575 17.6867 108.377 18.0245 108.117 18.311C107.856 18.5976 107.538 18.8265 107.183 18.9832C106.829 19.1399 106.445 19.2209 106.057 19.2209C105.669 19.2209 105.285 19.1399 104.93 18.9832C104.576 18.8265 104.258 18.5976 103.997 18.311C103.736 18.0245 103.539 17.6867 103.416 17.3195C103.294 16.9522 103.251 16.5635 103.288 16.1784ZM107.634 16.1784C107.634 14.9095 107.062 14.1675 106.059 14.1675C105.052 14.1675 104.485 14.9095 104.485 16.1784C104.485 17.4575 105.052 18.1938 106.059 18.1938C107.062 18.1938 107.634 17.4524 107.634 16.1784Z"
            fill="black"
          />
          <path
            d="M110.379 13.2535H111.495V14.1835H111.581C111.728 13.8495 111.976 13.5695 112.29 13.3825C112.604 13.1956 112.968 13.111 113.333 13.1405C113.619 13.1191 113.905 13.1621 114.172 13.2662C114.439 13.3703 114.679 13.5329 114.874 13.742C115.069 13.9511 115.214 14.2013 115.299 14.4741C115.384 14.7469 115.407 15.0352 115.365 15.3178V19.1073H114.206V15.6079C114.206 14.6672 113.796 14.1993 112.94 14.1993C112.746 14.1903 112.552 14.2232 112.372 14.2958C112.192 14.3684 112.03 14.4789 111.897 14.6198C111.764 14.7607 111.663 14.9287 111.601 15.1121C111.539 15.2956 111.517 15.4903 111.538 15.6828V19.1073H110.379V13.2535Z"
            fill="black"
          />
          <path
            d="M121.912 11.7966V13.2807H123.183V14.2538H121.912V17.2639C121.912 17.8771 122.165 18.1456 122.742 18.1456C122.889 18.1451 123.037 18.1362 123.183 18.1189V19.0812C122.975 19.1183 122.764 19.1381 122.553 19.1402C121.265 19.1402 120.752 18.6883 120.752 17.5597V14.2538H119.82V13.2807H120.752V11.7966H121.912Z"
            fill="black"
          />
          <path
            d="M124.766 10.9688H125.914V14.1946H126.006C126.16 13.8575 126.415 13.5759 126.735 13.3883C127.055 13.2006 127.426 13.116 127.796 13.146C128.08 13.1306 128.364 13.1779 128.628 13.2845C128.892 13.3912 129.128 13.5546 129.322 13.763C129.515 13.9715 129.659 14.2198 129.745 14.4904C129.831 14.761 129.856 15.0471 129.818 15.3283V19.1077H128.658V15.6133C128.658 14.6783 128.221 14.2048 127.403 14.2048C127.204 14.1885 127.003 14.2158 126.816 14.2848C126.628 14.3537 126.458 14.4627 126.318 14.6041C126.177 14.7454 126.069 14.9157 126.001 15.1031C125.933 15.2905 125.907 15.4904 125.925 15.6889V19.1077H124.766L124.766 10.9688Z"
            fill="black"
          />
          <path
            d="M136.572 17.5267C136.415 18.0617 136.074 18.5245 135.609 18.8341C135.144 19.1437 134.585 19.2804 134.029 19.2203C133.642 19.2304 133.258 19.1566 132.902 19.0038C132.547 18.851 132.229 18.6229 131.971 18.3355C131.713 18.048 131.521 17.708 131.408 17.339C131.295 16.97 131.264 16.581 131.317 16.1988C131.266 15.8154 131.297 15.4255 131.41 15.0554C131.523 14.6854 131.714 14.3438 131.971 14.0538C132.227 13.7638 132.543 13.5322 132.898 13.3746C133.252 13.217 133.636 13.1372 134.024 13.1405C135.657 13.1405 136.642 14.2533 136.642 16.0915V16.4946H132.497V16.5593C132.479 16.7742 132.506 16.9904 132.577 17.1942C132.647 17.3979 132.76 17.5848 132.907 17.7426C133.054 17.9005 133.233 18.026 133.431 18.111C133.63 18.1961 133.844 18.2387 134.061 18.2363C134.338 18.2695 134.618 18.2197 134.867 18.0934C135.116 17.967 135.321 17.7697 135.457 17.5267L136.572 17.5267ZM132.497 15.6402H135.462C135.477 15.4437 135.45 15.2464 135.384 15.0608C135.317 14.8753 135.213 14.7057 135.076 14.5629C134.94 14.4201 134.776 14.3072 134.593 14.2316C134.411 14.156 134.215 14.1192 134.017 14.1237C133.817 14.1212 133.618 14.1587 133.432 14.2341C133.247 14.3094 133.078 14.421 132.937 14.5623C132.795 14.7037 132.683 14.8718 132.608 15.057C132.532 15.2421 132.495 15.4404 132.497 15.6402H132.497Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_58750_15848">
            <rect width="156" height="52" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}
